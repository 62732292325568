import './report-settings-users-list-form.scss';
import { FC, useEffect, useState } from 'react'
import { BasicUser, Roles} from 'src/models';
import { Box } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { HmyAutocomplete } from 'src/components';
import { useFetchAndLoad } from 'src/hooks';
import { BasicUserModelService, userServices } from 'src/services';
import { GetSelectableUsersDto } from 'src/dtos';
import { basicUserAdapater } from 'src/adapters/user.adapter';
import ReportSettingsUserListFormElement from '../report-settings-user-list-form-element';

type ReportSettingsUsersListFormProps = {
    users: BasicUser[];
    usersCopy: BasicUser[];
    setUsersCopy: (value: BasicUser[]) => void;
    handleCancelChanges: () => void;
    handleSetUsers: (addUsers: string[], removeUsers: string[]) => void;
  };

const ReportSettingsUsersListForm: FC<ReportSettingsUsersListFormProps> = ({ users, usersCopy, setUsersCopy, handleCancelChanges, handleSetUsers }) => {

    const { loading, callEndpoint } = useFetchAndLoad();
    const [newUser, setNewUser] = useState<string | null>(null);
    const [optionsUser, setOptionsUser] = useState<BasicUser[]>([]);


    const getSelectableUsers = async (request: GetSelectableUsersDto) => await callEndpoint(userServices.getSelectableUsers(request));

    const adaptBasicUsers = (data: BasicUserModelService[]) => {

        let newBasicUsers = data.map(x => basicUserAdapater(x));

        setOptionsUser(newBasicUsers);
    };

    const handleDeleteUser = (userId: string) => {
        
        const usersCopyIndex = usersCopy.findIndex(x => x.id === userId);
            let copyArr = [...usersCopy];
            copyArr.splice(usersCopyIndex, 1);
            setUsersCopy(copyArr);
    }

    const handleUpdateUser = (value: string | null) => {
        let arr = [...usersCopy];

        if(value !== null && usersCopy.findIndex(x => x.email === value) === -1)
        {//Es nuevo
            const userNew = optionsUser.find(x => x.email === value);
            if(userNew){
                arr.push({
                    id: userNew.id,
                    email: userNew.email,
                    fullName: userNew.fullName
                });

                setUsersCopy(arr);
            }   
        }
    }

    const handleLoadUsers = async () => {
        try {
            let request: GetSelectableUsersDto = {
                roleName: Roles.CUSTOMER,
                usersDiscard: usersCopy.map(x => x.id),
                withoutSupervisor: false
            };

            const response = await getSelectableUsers(request);
            adaptBasicUsers(response.data);

        } catch (error) {
        }
    }

    const hasChanges = (): boolean => {
        let arr: BasicUser[] = usersCopy.filter(x => users.findIndex(u => u.id === x.id) === -1);
        return arr.length > 0 || usersCopy.length !== users.length;
    }

    const handleSaveChanges = () => {
        const deleteUsers = users.filter(x => usersCopy.findIndex(u => u.id === x.id) === -1);
        const addUsers = usersCopy.filter(x => users.findIndex(u => u.id === x.id) === -1);

        handleSetUsers(addUsers.map(x => x.id), deleteUsers.map(x => x.id));
    }

    useEffect(() => {
        handleLoadUsers();
      },[usersCopy]);

  return (
    <Box
        className="project-settings-users-list-form-container"
    >
        {usersCopy.map((user, index) => (
            <ReportSettingsUserListFormElement 
                key={`${user.id}-${index}`}
                user={user}
                handleDeleteUser={handleDeleteUser}
            />
        ))}
        <Box
            className="report-settings-add-form"
        >
            <HmyAutocomplete 
                label="searchUser"
                showLabel={true}
                fullWidth={true}
                value={newUser}
                loading={loading}
                options={optionsUser.map(x => x.email)}
                helperText={null}
                setValue={handleUpdateUser}
                loadResults={handleLoadUsers}
            />
        </Box>      
        {hasChanges()
            ?
            <Box
                className="report-settings-user-form-buttons"
            >
                <Box
                    onClick={() => handleSaveChanges()}
                    className="report-settings-user-form-button"
                >
                    <FormattedMessage id="save"/>
                </Box> 
                <Box
                    onClick={() => handleCancelChanges()}
                    className="report-settings-user-form-button-cancel"
                >
                    <FormattedMessage id="btn.cancel"/>
                </Box>
            </Box>
            :
            null
        }
    </Box>
  )
}

export default ReportSettingsUsersListForm