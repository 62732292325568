import { Box, Dialog, useMediaQuery } from '@mui/material';
import { FC, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import { HmyBtn } from 'src/components';
import { HmyInputTextarea } from 'src/components/hmy-input-textarea';
import { useCreateIncidence } from 'src/hooks/use-incidence.hook';
import { ErrorMessage, SuccessMessage } from 'src/models';
import { BasicOrder } from 'src/models/orders';
import { setMessage } from 'src/redux/states';
import IncidenceArticlesList from '../incidence-articles-list';
import './incidence-modal.scss';

type IncidenceModalProps = {
  orderData: BasicOrder;
};

const IncidenceModal: FC<IncidenceModalProps> = ({ orderData }) => {
  const [open, setOpen] = useState<boolean>(false);
  const [description, setDescription] = useState<string>('');
  const [checkedArticles, setCheckedArticles] = useState<string[]>([]);
  const matches = useMediaQuery('(max-width: 768px)');
  const { mutationCreateIncidence } = useCreateIncidence(orderData.saleOrderId);
  const dispatch = useDispatch();

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleSubmitIncidence = () => {
    const incidenceData = {
      description,
      lines: checkedArticles.map((article) => ({ saleOrderLineId: article })),
      saleOrderId: orderData.saleOrderId,
    };

    if (incidenceData.description.trim() !== '') {
      mutationCreateIncidence.mutate(incidenceData, {
        onSuccess: () => {
          dispatch(setMessage(SuccessMessage('success.CreateIncidence', true)));
          handleClose();
        },
        onError: () => {
          dispatch(setMessage(ErrorMessage('error.CreateIncidence', true)));
        },
      });
    }
  };

  return (
    <Box>
      <HmyBtn title="btn.addIncidence" width={200} filled fullwith={false} color="primary" handleClick={handleOpen} />
      {open && (
        <Dialog open={open} onClose={handleClose} className="incidence-modal" fullScreen={matches}>
          <Box className="incidence-modal-container">
            <Box className="incidence-modal-header">
              <span>
                <FormattedMessage id="orderIncidenceTitle" />
              </span>
              <span>
                <FormattedMessage id="orderIncidenceSubtitle" />
              </span>
            </Box>
            <HmyInputTextarea value={description} setValue={setDescription} />
            <span className="incidence-modal-section-text">
              <FormattedMessage id="orderIncidenceArticles" />
            </span>
            <IncidenceArticlesList
              orderData={orderData}
              checkedArticles={checkedArticles}
              setCheckedArticles={setCheckedArticles}
            />

            <Box className="incidence-modal-controls">
              <HmyBtn title="btn.cancel" width={100} fullwith={false} color="secondary" handleClick={handleClose} />

              <HmyBtn
                title="btn.addIncidence"
                width={250}
                fullwith={false}
                filled
                color="primary"
                handleClick={handleSubmitIncidence}
                disabled={description.trim() === ''}
              />
            </Box>
          </Box>
        </Dialog>
      )}
    </Box>
  );
};

export default IncidenceModal;
