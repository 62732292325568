import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { BasicCatalogueLineModelService, FilterCatalagueModelService, catalogueServices } from 'src/services/catalogue';
import { useFetchAndLoad } from './use-fetch-load.hook';

export const CATEGORY_NONE = 'categoryNone';

export function useCatalogue(projectId: string | undefined) {
  const { callEndpoint } = useFetchAndLoad();
  const [catalogueId, setCatalogueId] = useState<string>('');
  const { data, isLoading, isFetching } = useQuery({
    queryKey: ['getCatalogues', projectId],
    queryFn: () => callEndpoint(catalogueServices.getCatalogues(projectId ?? '')),
    select: ({ data }) => {
      if (data.length === 1 && catalogueId !== data[0].catalogueId) {
        setCatalogueId(data[0].catalogueId);
      }
      return data;
    },
    enabled: !!projectId,
  });

  const { data: catalogueData } = useQuery({
    queryKey: ['getCatalogue', projectId, catalogueId],
    queryFn: () => callEndpoint(catalogueServices.getCatalogue(projectId ?? '', catalogueId)),
    select: ({ data }) => {
      return data.catalogueLines.map((line: BasicCatalogueLineModelService) => ({ ...line, quantity: 0 }));
    },
    enabled: !!projectId && !!catalogueId,
  });

  return {
    data,
    isLoading: isLoading || isFetching,
    catalogueId,
    setCatalogueId,
    catalogueData,
    catalogueCategories: data
      ? data.reduce((total: string[], catalogue: FilterCatalagueModelService) => {
          if (
            catalogue.transportCategoryName === '' &&
            total.findIndex((category) => category === CATEGORY_NONE) === -1
          ) {
            return [...total, CATEGORY_NONE];
          } else if (total.findIndex((category) => category === catalogue.transportCategoryName) === -1) {
            return [...total, catalogue.transportCategoryName];
          }
          return total;
        }, [])
      : [],
  };
}
