import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { Box, Typography, useMediaQuery } from '@mui/material';
import { FC, useRef, useState } from 'react';
import { FormattedDate, FormattedMessage } from 'react-intl';
import { CompleteProject } from 'src/models';
import { BasicShoppingOrder } from 'src/services/catalogue';
import { formatDate } from 'src/utilities';
import { calculeOrderDelivery } from '../calcule-order-delivery.helpers';
import '../order.scss';

type NewOrderHeaderProps = {
  orderData: BasicShoppingOrder;
  projectData: CompleteProject;
  setPhoneNumber: (phoneNumber: string) => void;
  customerData: { id: String; email: string } | null;
};

const NewOrderHeader: FC<NewOrderHeaderProps> = ({ orderData, projectData, setPhoneNumber, customerData }) => {
  const [editPhoneNumber, setEditPhoneNumber] = useState<string>(orderData.phoneNumber);
  const matches = useMediaQuery('(max-width: 768px)');
  const inputRef = useRef<HTMLInputElement>(null);

  const handleEditPhone = () => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  return (
    <>
      <Typography className="order-title-text">
        <FormattedMessage id="generalInformation" />
      </Typography>
      <Box className="order-header">
        <div>
          <span>{projectData.projectNumber}</span>
          <span>{projectData.description}</span>
        </div>

        <div>
          <span style={{ display: 'flex', columnGap: '4px' }}>
            <span>
              <FormattedMessage id="purchaseDate" />
            </span>
            <span>
              <FormattedDate value={orderData.date ? formatDate(new Date(orderData.date)) : formatDate(new Date())} />
            </span>
          </span>
          <span style={{ display: 'flex', columnGap: '4px' }}>
            <span>
              <FormattedMessage id="deliveryDate" />
            </span>
            <span>
              <FormattedDate value={calculeOrderDelivery(orderData)} />
            </span>
          </span>
        </div>
        <div className="order-header-inline">
          <FormattedMessage id="deliveryAddress" /> {projectData.address}
        </div>
        <div>
          <span className="order-header-phone">
            <FormattedMessage id="phone" />:
            <input
              ref={inputRef}
              value={editPhoneNumber}
              onChange={(e) => setEditPhoneNumber(e.target.value)}
              onBlur={(e) => setPhoneNumber(e.target.value)}
            />
            <span onClick={handleEditPhone}>
              <EditOutlinedIcon style={{ fontSize: '18px' }} />
            </span>
          </span>
          <span>
            <FormattedMessage id="email" />
            {`: ${customerData?.email ?? ''}`}
          </span>
        </div>
      </Box>
    </>
  );
};

export default NewOrderHeader;
