import './projects.scss'
import { FC, useEffect, useState } from 'react'
import { Box, Fab, FormControlLabel, Link, Switch } from '@mui/material';
import { BasicProject, ErrorMessage, GeneralCollection, SuccessMessage, TableHeaderElement } from 'src/models';
import { GetProjectsDto } from 'src/dtos';
import { useFetchAndLoad } from 'src/hooks';
import { BasicProjectModelService, projectServices } from 'src/services';
import { basicProjectAdapter } from 'src/adapters';
import { HmyInputText, ROUTE_IMPORT_PROJECTS, HmyTable, LoadComponent, ModalComponent, HmyInfiniteScroll, HmyBtn, ROUTE_PROJECT } from 'src/components';
import { FormattedMessage, useIntl } from 'react-intl';
import ErrorIcon from '@mui/icons-material/Error';
import CAN from '../../permissions';
import { IsAdmin } from 'src/utilities';
import { useDispatch, useSelector } from 'react-redux';
import { currentUserSelector, setMessage } from 'src/redux/states';
import useMediaQuery from '@mui/material/useMediaQuery';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import NoProjects from './no-projects';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ADD_PROJECTS, READ_CLOSED_PROJECTS } from 'src/permissions/permissions';
import HmyTableRowProject from './hmy-table-row-project';
import HmyCardProject from './hmy-card-project';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import SouthIcon from '@mui/icons-material/South';
import NorthIcon from '@mui/icons-material/North';
import { useMatomo } from '@jonkoops/matomo-tracker-react';

const Projects : FC = () => {

  const intl = useIntl();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const matches = useMediaQuery('(max-width: 768px)');
  const [searchParams, setSearchParams] = useSearchParams();
  const currentUser = useSelector(currentUserSelector);
  const { trackPageView, pushInstruction } = useMatomo();
  const { loading, callEndpoint } = useFetchAndLoad();
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [selectedProject, setSelectedProject] = useState<BasicProject>();
  const [projects, setProjects] = useState<GeneralCollection<BasicProject>>();
  const [searchText, setSearchText] = useState<string>("");
  const [paginatorRequest, setPaginatorRequest] = useState<GetProjectsDto>({
    page: 0,
    take: matches ? 10 : 5,
    columnOrder: "InternalStartDate",
    typeOrder: "DESC",
    filter: "",
    showClosedProjects: false,
    showDeletedProjects: false
  });

  const dateText = intl.formatMessage({id: "date"});
  const number = intl.formatMessage({id: "number"});
  const name = intl.formatMessage({id: "name"});
  const contact = intl.formatMessage({id: "contact"});
  const remove = intl.formatMessage({id: "remove"});
  const users = intl.formatMessage({id: "user.many"});

  const handleChangeOrder = () => {
    const order: string = paginatorRequest.typeOrder === "DESC" ? "ASC" : "DESC";
    setSearchParams({ filter: searchText, page: "1", take: paginatorRequest.take.toString(), typeOrder: order });
  }

  const headerELementList: TableHeaderElement[] = [
    {
      element: 
      <Box 
        sx={{display: "flex", flexDirection: "row", gap: "5px", alignItems: "center"}}
      >
        <p>{dateText}</p>
        {paginatorRequest.typeOrder.toLowerCase().includes("asc") ? <NorthIcon fontSize="inherit" />  : <SouthIcon fontSize="inherit" />}
        <CalendarMonthIcon fontSize="inherit" />
      </Box>,
      width: 10,
      withBorder: true,
      align: "center",
      onClick: handleChangeOrder
    },
    {
      element: number,
      width: 10,
      withBorder: true,
      align: "center",
      onClick: null
    },
    {
      element: name,
      width: 35,
      withBorder: true,
      align: "center",
      onClick: null
    },
    {
      element: IsAdmin(currentUser) ? users : contact,
      width: 30,
      withBorder: true,
      align: "center",
      onClick: null
    },
    {
      element: remove,
      width: 15,
      withBorder: false,
      align: "center",
      onClick: null
    }
  ]

  const handleClickImport = () => {
      navigate(ROUTE_IMPORT_PROJECTS)
  }

  const getListProjects = async () => await callEndpoint(projectServices.getProjects(paginatorRequest));
  const deleteProject = async (projectId: string) => await callEndpoint(projectServices.deleteProject(projectId));

  const adaptListProjects = (data: GeneralCollection<BasicProjectModelService>) => {

    let listProject: BasicProject[] = [];
    let newProjects = data.items.map((x: BasicProjectModelService) => { return basicProjectAdapter(x) });
    if(matches && paginatorRequest.page !== 1){
      listProject = (projects?.items ?? []).concat(newProjects);
    }else{
      listProject = newProjects;
    }

    setProjects({
      items: listProject,
      total: data.total,
      currentPage: data.currentPage,
      pages: data.pages
    });

  };

  const handleEnterSearch = () => {
    if(searchText && searchText !== ""){
      setSearchParams({ filter: searchText, page: "1", take: paginatorRequest.take.toString(), typeOrder: paginatorRequest.typeOrder });
    }
    else{
      setSearchParams({ page: "1", take: paginatorRequest.take.toString(), typeOrder: paginatorRequest.typeOrder });
    }
  }

  const handleChangeDeleted = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPaginatorRequest({
      ...paginatorRequest,
      showDeletedProjects: event.target.checked,
      page: 1
    });
  };

  const handleChangeClosed = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPaginatorRequest({
      ...paginatorRequest,
      showClosedProjects: event.target.checked,
      page: 1
    });
  };

  const handleDismissModal = () => {
    setOpenModal(false);
    setSelectedProject(undefined);
  }

  const handleAcceptModal = async () => {
    try {
      await deleteProject(selectedProject!.projectId);
      dispatch(setMessage(SuccessMessage(selectedProject?.isDeleted ? "success.projectRecover" : "success.projectDeleted", true)));
      setSearchParams({ filter: searchText, page: "1", take: paginatorRequest.take.toString(), typeOrder: paginatorRequest.typeOrder });
    } catch (error) {
      dispatch(setMessage(ErrorMessage(selectedProject?.isDeleted ? "error.projectRecover" : "error.projectDeleted", true)))
    } finally{
      setOpenModal(false);
      setSelectedProject(undefined);
    }
  }

  const handleClickProject = (projectId: string) => {
    navigate(ROUTE_PROJECT.replace(":projectid", projectId));
  }

  const handleChangePage = (page: number) => {
    setSearchParams({ filter: searchText, page: page.toString(), take: paginatorRequest.take.toString(), typeOrder: paginatorRequest.typeOrder });
  }

  const handleChangeRowsPerPage = (pages: number) => {
    setSearchParams({ filter: searchText, page: "1", take: pages.toString(), typeOrder: paginatorRequest.typeOrder });
  }

  useEffect(() => {
    if(selectedProject !== undefined){
      setOpenModal(true);
    }
  },[selectedProject])

  useEffect(() => {
    setSearchText(searchParams.get("filter") ?? "");
    setPaginatorRequest({
      ...paginatorRequest,
      filter: searchParams.get("filter") ?? "",
      page: Number(searchParams.get("page") ?? 1),
      take: Number(searchParams.get("take") ?? paginatorRequest.take),
      typeOrder: searchParams.get("typeOrder") ?? paginatorRequest.typeOrder
    });
  },[searchParams])

  useEffect(() => {
    if(paginatorRequest.page > 0){
      getListProjects()
        .then(response => adaptListProjects(response.data))
        .catch(error => {})
    }
  },[paginatorRequest])

  useEffect(() => {
    pushInstruction('setUserId', currentUser.email);

    trackPageView({
      documentTitle: 'Projects'
    });
  }, [])

  return (
    !loading && projects?.items.length === 0 && paginatorRequest.filter === ""
    ?
      <NoProjects />
    :
      <Box 
        className="projects-container"
      >
        <Box
          className="projects-main"
        >
          {!loading && projects
          ?
          <>
            <Box
              className="projects-search"
            >
              <HmyInputText
                title="searchProject" 
                width={matches ? "100%" : 500}
                value={searchText}
                setValue={setSearchText}
                handleEnter={handleEnterSearch}
              />
              {CAN(ADD_PROJECTS) && (
                <HmyBtn 
                  title="addNewProject"
                  fullwith={false}
                  width={matches ? 230 : 300}
                  color="primary"
                  handleClick={handleClickImport}
                />
              )}
            </Box>
            <Box
              className="projects-switch-container"
            >
              <FormControlLabel
                control={<Switch
                  checked={paginatorRequest.showDeletedProjects}
                  onChange={handleChangeDeleted}
                  color="secondary"
                  inputProps={{ 'aria-label': 'controlled' }}
                  size="small"
                />}
                label={<FormattedMessage id={matches ? "deleted" : "PROJECT_FILTER_SeeDeleted"}/>}
              />
                {CAN(READ_CLOSED_PROJECTS) && (
                  <FormControlLabel
                    control={<Switch
                      checked={paginatorRequest.showClosedProjects}
                      onChange={handleChangeClosed}
                      color="secondary"
                      inputProps={{ 'aria-label': 'controlled' }}
                      size="small"
                    />}
                    label={<FormattedMessage id={matches ? "closed" : "PROJECT_FILTER_SeeClosed"}/>}
                    className="switch-show-closed"
                  />
                )}
            </Box>
            <HmyTable
              headerELementList={headerELementList}
              currentPage={projects?.currentPage ?? 1}
              pages={projects?.pages ?? 0}
              rowsPerPage={paginatorRequest.take}
              isChekable={false}
              isChecked={false}
              handleCheck={() => (false)}
              handleChangePage={handleChangePage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
            >
              {loading 
              ?
                <LoadComponent />
              :
                projects?.items.map((project, index) => (
                  <HmyTableRowProject 
                    key={`${project.projectNumber}-${index}`}
                    isFirst={index === 0}
                    isLast={index === projects?.items.length - 1}
                    project={project}
                    currentUser={currentUser}
                    handleClickProject={handleClickProject}
                    setSelectedProject={setSelectedProject}
                  />
                ))}  
            </HmyTable>
            {matches
            ?
            <HmyInfiniteScroll
              currentPage={projects?.currentPage ?? 1}
              pages={projects?.pages ?? 0}
              dataLength={projects?.items.length ?? 0}
              handleChangePage={handleChangePage}
            >
              {loading 
                ?
                  <LoadComponent />
                :
                  projects?.items.map((project, index) => (
                    <HmyCardProject 
                      key={`${project.projectNumber}-${index}`}
                      project={project}
                      currentUser={currentUser}
                      handleClickProject={handleClickProject}
                      setSelectedProject={setSelectedProject}
                    />
                  ))
              }
            </HmyInfiniteScroll>
            :
            null
            }
          </>
          :
          null
          }
        </Box>
        <ModalComponent
          isOpen={openModal}
          disMissText={selectedProject?.isDeleted ? "recover" : "remove"}
          acceptText="btn.cancel"
          colorAcceptButton="green"
          handleDismiss={handleDismissModal}
          handleAccept={handleAcceptModal}
        >
          <Box>
            <ErrorIcon className="error-icon" />
          </Box>
          <Box
            className="project-modal-text"
          >
            {selectedProject?.isDeleted
            ?
              <FormattedMessage id="reactivateProject" values={{ value: <b>{selectedProject.projectName ?? selectedProject.description}</b>}} />
            :
              <FormattedMessage id="deleteProject" values={{ value: <b>{selectedProject?.projectName ?? selectedProject?.description}</b>}} />
            }
          </Box>
        </ModalComponent>
        <Fab className="project-btn-up">
          <Link
            href="#"       
          >
            <ArrowUpwardIcon sx={{fontSize: '40px'}} />
          </Link>    
        </Fab> 
      </Box>
  )
}

export default Projects;