import { Box, Typography } from '@mui/material';
import { FC, useState } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import { FormattedDate, FormattedMessage } from 'react-intl';
import { es } from 'date-fns/locale';

import './hmy-calendar-filter.scss';

// import required css from library
import 'react-datepicker/dist/react-datepicker.css';

type HmyCalendarFilterProps = {
  title: string; //Esto tiene que ser el id de la traducción
  date: Date;
  setDate: (date: Date) => void;
  position: 'left' | 'right';
};

const HmyCalendarFilter: FC<HmyCalendarFilterProps> = ({ title, date, setDate, position }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  registerLocale('es', es);
  const handleChange = (value: Date | null) => {
    if (value !== null) {
      setIsOpen(false);
      setDate(value);
    }
  };

  const toggleOpenCalendar = () => {
    setIsOpen(!isOpen);
  };

  return (
    <Box
      className={`hmy-calendar-filter-container hmy-calendar-filter-container-${position}`}
      onClick={() => toggleOpenCalendar()}
    >
      <Typography className="hmy-calendar-filter-text">
        <FormattedMessage id={title} />
      </Typography>
      <Typography className="hmy-calendar-filter-text">
        <FormattedDate value={date} />
      </Typography>

      {isOpen && (
        <DatePicker
          locale="es"
          className="hmy-calendar-filter-picker"
          selected={date}
          onChange={(e) => handleChange(e)}
          onClickOutside={toggleOpenCalendar}
          inline
        />
      )}
    </Box>
  );
};

export { HmyCalendarFilter };
