import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  IncidenceModelService,
  PostIncidenceCommentModelService,
  PostIncidenceModelService,
  incidenceServices,
} from 'src/services/incidence';
import { useFetchAndLoad } from './use-fetch-load.hook';

export function useIncidence(incidenceId: string) {
  const { callEndpoint } = useFetchAndLoad();

  const { data, isLoading, isFetching } = useQuery({
    queryKey: ['getIncidence', incidenceId],
    queryFn: () => callEndpoint(incidenceServices.getIncidence(incidenceId)),
    select: ({ data }: { data: IncidenceModelService }) => ({
      ...data,
      comments: data.comments?.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()),
    }),
    enabled: !!incidenceId,
  });

  return { data, isLoading: isLoading || isFetching };
}

export function useCreateIncidence(orderId: string) {
  const queryClient = useQueryClient();
  const { loading, callEndpoint } = useFetchAndLoad();

  const mutationCreateIncidence = useMutation({
    mutationFn: (incidence: PostIncidenceModelService) => callEndpoint(incidenceServices.postIncidence(incidence)),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['getOrder', orderId] });
    },
  });

  return { mutationCreateIncidence };
}

export function useCreateComment() {
  const queryClient = useQueryClient();
  const { loading, callEndpoint } = useFetchAndLoad();

  const mutationCreateComment = useMutation({
    mutationFn: (incidence: PostIncidenceCommentModelService) =>
      callEndpoint(incidenceServices.postIncidenceComment(incidence)),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['getIncidence'] });
    },
  });

  return { mutationCreateComment };
}
