import './groupings.scss'
import { FC, useEffect, useState } from 'react';
import { Box, useMediaQuery } from '@mui/material';
import CAN from '../../permissions';
import { HmyBtn, HmyHorizontalInfiniteScroll, HmyInputText, LoadComponent, ModalComponent, ROUTE_USER_GROUPING, ROUTE_USER_GROUPINGS_NEW } from 'src/components';
import { useAsync, useFetchAndLoad } from 'src/hooks';
import { ErrorMessage, GeneralCollection, RequestCollection, SuccessMessage, UserGroupingBase } from 'src/models';
import { ADD_GROUPING } from 'src/permissions/permissions';
import { useNavigate } from 'react-router-dom';
import { groupingServices, UserGroupingBaseModelService } from 'src/services';
import { groupingBaseAdapter } from 'src/adapters';
import GroupingCard from './grouping-card';
import { useDispatch, useSelector } from 'react-redux';
import { currentUserSelector, setMessage } from 'src/redux/states';
import ErrorIcon from '@mui/icons-material/Error';
import { FormattedMessage } from 'react-intl';
import { useMatomo } from '@jonkoops/matomo-tracker-react';

const Groupings : FC = () => {

  let position = 0;
  const currentUser = useSelector(currentUserSelector);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const matches = useMediaQuery('(max-width: 768px)');
  const { loading, callEndpoint } = useFetchAndLoad();
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [selectedGrouping, setSelectedGrouping] = useState<UserGroupingBase>();
  const [groupings, setGroupings] = useState<GeneralCollection<UserGroupingBase>>();
  const [groupingsCopy, setGroupingsCopy] = useState<UserGroupingBase[]>();
  const [searchText, setSearchText] = useState<string>("");
  const { trackPageView, pushInstruction } = useMatomo();
  const [paginatorRequest, setPaginatorRequest] = useState<RequestCollection>({
    page: 1,
    take: 1000,
    columnOrder: "Created",
    typeOrder: "DESC",
    filter: ""
  });

  const getListGrouping = async () => await callEndpoint(groupingServices.getGroupings(paginatorRequest));
  const deleteGrouping = async (groupingId: string) => await callEndpoint(groupingServices.deleteGrouping(groupingId));

  const adaptListGrouping = (data: GeneralCollection<UserGroupingBaseModelService>) => {
    let listGrouping: UserGroupingBase[] = [];
    let newGroupings = data.items.map((x: UserGroupingBaseModelService) => { return groupingBaseAdapter(x) });
    if(paginatorRequest.page !== 1){
      listGrouping = (groupings?.items ?? []).concat(newGroupings);
    }else{
      listGrouping = newGroupings;
    }

    setGroupings({
      items: listGrouping,
      total: data.total,
      currentPage: data.currentPage,
      pages: data.pages
    });

  };

  const handleClickGrouping = (groupingId: string) => {
    navigate(ROUTE_USER_GROUPING.replace(":groupingid", groupingId));
  }

  const handleAddGrouping = () => {
    navigate(ROUTE_USER_GROUPINGS_NEW)
  }

  const handleEnterSearch = () => {
    if(groupings){
      setGroupingsCopy(groupings.items.filter(x => x.name.toLowerCase().includes(searchText.toLowerCase())));
    }
  }

  const handleDismissModal = () => {
    setOpenModal(false);
    setSelectedGrouping(undefined);
  }

  const handleAcceptModal = async () => {
    try {
      await deleteGrouping(selectedGrouping!.id);
      dispatch(setMessage(SuccessMessage("success.userGroupingDeleted", true)));
      //Si ha ido bien la eliminación recargamos
      const response = await getListGrouping()
      adaptListGrouping(response.data);
    } catch (error) {
      dispatch(setMessage(ErrorMessage("error.DeletingUserGrouping", true)))
    } finally{
      setOpenModal(false);
      setSelectedGrouping(undefined);
    }
  }

  useEffect(() => {
    if(selectedGrouping !== undefined){
      setOpenModal(true);
    }
  },[selectedGrouping]);

  useEffect(() => {
    if(groupings){
      setGroupingsCopy([...groupings.items]);
    }
  },[groupings])

  useEffect(() => {
    pushInstruction('setUserId', currentUser.email);

    trackPageView({
      documentTitle: 'Groupings'
    });
  },[]);

  useAsync(getListGrouping, adaptListGrouping, () => { }, () => { }, []);

  return (
    <Box 
      className="groupings-container"
    >
        {loading ? <LoadComponent /> : null}
        <Box
          className="groupings-search"
        >
          <HmyInputText
            title="searchGrouping" 
            width={matches ? "100%" : 500}
            value={searchText}
            setValue={setSearchText}
            handleEnter={handleEnterSearch}
          />
          {CAN(ADD_GROUPING) && (
            <HmyBtn 
              title="newGrouping"
              fullwith={false}
              width={matches ? 230 : 300}
              color="primary"
              handleClick={handleAddGrouping}
            />
          )}
        </Box>
        {groupingsCopy
          ?
          <Box
            className="groupings-list"
          >
            <HmyHorizontalInfiniteScroll
              slidesPerView={matches ? 1 : 4}
            >
              {groupingsCopy.map((grouping, index) => {
                position = position === 3 ? 1 : position + 1;
                return <GroupingCard 
                  key={grouping.id}
                  grouping={grouping}
                  color={position === 1 ? "primary" : (position === 2 ? "secondary" : "tertiary")}
                  handleSelectedGrouping={setSelectedGrouping}
                  handleClickGrouping={handleClickGrouping}
                />
              })}
            </HmyHorizontalInfiniteScroll>
          </Box>
        :
          null
        }
        <ModalComponent
          isOpen={openModal}
          disMissText="remove"
          acceptText="btn.cancel"
          colorAcceptButton="red"
          handleDismiss={handleDismissModal}
          handleAccept={handleAcceptModal}
        >
          <Box>
            <ErrorIcon className="error-icon" />
          </Box>
          <Box
            className="groupings-modal-text"
          >
            <FormattedMessage id="deleteUserGrouping" values={{ value: <b>{selectedGrouping?.name}</b>}} />
          </Box>
        </ModalComponent>
    </Box>
  )
}

export default Groupings;