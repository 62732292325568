import './grouping-file-add-form.scss'
import { FC, useState } from 'react'
import { HmyAutocompleteAdd, HmyFormElement, HmyFormSection } from 'src/components';
import { Box, TextField, Typography, useMediaQuery } from '@mui/material';
import { FormattedMessage, useIntl } from 'react-intl';
import { formatDate } from 'src/utilities';
import { useAsync, useFetchAndLoad } from 'src/hooks';
import { groupingServices, UserGroupingCategoryModelService, UserGroupingTagModelService, UserGroupingTypeModelService } from 'src/services';
import { GeneralCollection, UserGroupingCategory, UserGroupingTag, UserGroupingType } from 'src/models';
import { groupingCategoryAdapter, groupingTagAdapter, groupingTypeAdapter } from 'src/adapters';
import DeleteIcon from '@mui/icons-material/Delete';

export type GroupingNewFileForm = {
  finalName: string;
  nameDescription: string;
  type: string;
  category: string;
  tags: string[];
  date: string;
  description: string;
}

type GroupingFileAddFormProps = {
  userGroupingId: string;
  values: GroupingNewFileForm;
  setValues: (values: GroupingNewFileForm) => void;
};

const GroupingFileAddForm: FC<GroupingFileAddFormProps> = ({ userGroupingId, values, setValues }) => {

  const intl = useIntl();

  const newElement = intl.formatMessage({id: "new"});
  const matches = useMediaQuery('(max-width: 768px)');
  
  const { loading, callEndpoint } = useFetchAndLoad();
  const [nameError, setNameError] = useState<string | null>(null);

  const [types, setTypes] = useState<UserGroupingType[]>([]);
  const [categories, setCategories] = useState<UserGroupingCategory[]>([]);
  const [tags, setTags] = useState<UserGroupingTag[]>([]);

  const [newType, setNewType] = useState<string | null>(null);
  const [newCategory, setNewCategory] = useState<string | null>(null);
  const [newTags, setNewTags] = useState<string[]>([]);

  const getTypes = async () => await callEndpoint(groupingServices.getTypes({
    userGroupingId: userGroupingId,
    categoryName: values.category
  }));

  const getCategories = async () => await callEndpoint(groupingServices.getCategories({
    userGroupingId: userGroupingId,
    userGroupingTypeId: types.find(x => x.name === values.type)?.id ?? ""
  }));

  const getTags = async () => await callEndpoint(groupingServices.getTags({
    userGroupingId: userGroupingId
  }));

  const adaptTypes = (data: GeneralCollection<UserGroupingTypeModelService>) => {

    let typeList =  data.items.map(x => groupingTypeAdapter(x));
    
    setTypes(typeList);

  };

  const adaptCategories = (data: GeneralCollection<UserGroupingCategoryModelService>) => {

    let categoryList =  data.items.map(x => groupingCategoryAdapter(x));
    
    setCategories(categoryList);

  };

  const adaptTags = (data: GeneralCollection<UserGroupingTagModelService>) => {

    let tagList =  data.items.map(x => groupingTagAdapter(x));
    
    setTags(tagList);

  };

  const validateName = (name: string) : number => {
    if(name === null || name.trim() === ""){
      setNameError("error.required");
      return 1;
    }
    else{
      setNameError(null);
      return 0;
    }
  }

  const handleChangeName = (event: React.ChangeEvent<HTMLInputElement>) => {
    if(nameError !== null){
        validateName(event.target.value);
    }
    setValues({
        ...values,
        nameDescription: event.target.value
    });
  };

  const handleChangeDate = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValues({
      ...values,
      date: formatDate(new Date(event.target.value))
    })
  }

  const handleChangeDescription = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValues({
      ...values,
      description: event.target.value
    });
  }

  const handleLoadTypes = async () => {
    const response = await getTypes();
    adaptTypes(response.data);
  }

  const handleSetType = (value: string | null) => {
    setValues({
      ...values,
      type: value ?? ""
    })    
  }

  const handleLoadCategories = async () => {
    const response = await getCategories();
    adaptCategories(response.data);
  }

  const handleSetCategory = (value: string | null) => {
    setValues({
      ...values,
      category: value ?? ""
    })  
  }

  const handleCancelElementTag = (value: string) => {
    let newTags = [...values.tags.filter(x => x !== value)];
    setValues({
        ...values,
        tags: newTags
    });
  }

  const handleLoadTags = async (filter: string) => {
    const response = await getTags();
    adaptTags(response.data);
  }

  const handleSetTags = (value: string | null) => {
    if(value !== null){
        let tagList = [...values.tags];
        tagList.push(value);
        setValues({
            ...values,
            tags: tagList
        });
    }  
  }


  const handleNewType = (value: string) => {
    const newValue = `${value} (${newElement})`;
    if(newType === null || newType !== newValue){
      setNewType(newValue);
      handleSetType(newValue);
    }
  }

  const handleNewCategory = (value: string) => {
    const newValue = `${value} (${newElement})`;
    if(newCategory === null || newCategory !== newValue){
      setNewCategory(newValue);
      handleSetCategory(newValue);
    }
  }

  const handleNewTag = (value: string) => {
    const newValue = `${value} (${newElement})`;
    if(!newTags.includes(newValue)){
      let newTagList = [...newTags];
      newTagList.push(newValue);
      setNewTags(newTagList);
    }
    
    if(!values.tags.includes(newValue)){
      handleSetTags(newValue);
    }
    
  }

  useAsync(getTypes, adaptTypes, () => { }, () => { }, [values.category]);
  useAsync(getCategories, adaptCategories, () => { }, () => { }, [values.type]);
  useAsync(getTags, adaptTags, () => { }, () => { }, []);

  return (
    <>
      <HmyFormSection>
        <HmyFormElement
          isMultiline={false}
          isFullWidth={false}
          showTitle={true}
          title="name"
        >
          <TextField 
              label="" 
              variant="standard" 
              value={values.nameDescription}
              onChange={handleChangeName}
              onBlur={e => validateName(values.nameDescription)}
              error={nameError !== null}
              helperText={nameError !== null ? <FormattedMessage id={nameError} /> : null}
              fullWidth
              inputProps={{style: {fontSize: matches ? 16 : 20}}}
          />   
        </HmyFormElement>
        <HmyFormElement
          isMultiline={false}
          isFullWidth={false}
          showTitle={true}
          title="date"
        >
          <TextField 
              label="" 
              variant="standard" 
              type="date"
              value={values.date}
              onChange={handleChangeDate}
              fullWidth
              inputProps={{style: {fontSize: matches ? 16 : 20}}}
          />   
        </HmyFormElement>
      </HmyFormSection>
      <HmyFormSection>
        <HmyFormElement
            isMultiline={false}
            isFullWidth={false}
            showTitle={true}
            title="type"
        >
          <HmyAutocompleteAdd 
              label="type"
              showLabel={false}
              fullWidth={true}
              value={values.type ?? ""}
              loading={loading}
              options={newType !== null ? types.map(x => x.name).concat([newType]) : types.map(x => x.name)}
              helperText={null}
              handleNewElement={handleNewType}
              setValue={handleSetType}
              loadResults={handleLoadTypes}
          /> 
        </HmyFormElement>
        <HmyFormElement
          isMultiline={false}
          isFullWidth={false}
          showTitle={true}
          title="category"
        >
          <HmyAutocompleteAdd 
            label="category"
            showLabel={false}
            fullWidth={true}
            value={values.category ?? ""}
            loading={loading}
            options={newCategory !== null ? categories.map(x => x.name).concat([newCategory]) : categories.map(x => x.name)}
            helperText={null}
            handleNewElement={handleNewCategory}
            setValue={handleSetCategory}
            loadResults={handleLoadCategories}
          />     
        </HmyFormElement>
      </HmyFormSection>
      <HmyFormSection>
        <HmyFormElement
          isMultiline={true}
          isFullWidth={false}
          showTitle={true}
          title="tag.many"
        >
          {values.tags.map((tag, index) => (
              <Box
                  key={`${tag}-${index}`}
                  className="user-grouping-form-element-loaded"
              >
                  <Typography fontSize={22} >{tag}</Typography>
                  <DeleteIcon 
                      onClick={() => handleCancelElementTag(tag)}
                      color="error"
                      style={{cursor: "pointer"}}
                  />
              </Box>
          ))}
          <HmyAutocompleteAdd 
            label="tag.many"
            showLabel={false}
            fullWidth={true}
            value={null}
            loading={loading}
            options={(tags.filter(x => !values.tags.includes(x.name)).map(x => x.name)).concat(newTags)}
            helperText={null}
            handleNewElement={handleNewTag}
            setValue={handleSetTags}
            loadResults={handleLoadTags}
          />  
        </HmyFormElement>
        <HmyFormElement
          isMultiline={false}
          isFullWidth={false}
          showTitle={true}
          title="description"
        >
          <TextField 
            label="" 
            variant="standard" 
            type="text"
            value={values.description}
            onChange={handleChangeDescription}
            multiline={true}
            fullWidth
            inputProps={{style: {fontSize: matches ? 16 : 20}}}
          />   
        </HmyFormElement>
      </HmyFormSection>
    </>
  )
}

export default GroupingFileAddForm;