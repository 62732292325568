import './hmy-table-row-project.scss';
import {FC} from 'react'
import { Box } from '@mui/material';
import { HmyTableRow, StringMultiple } from 'src/components';
import { BasicProject, CurrentUser } from 'src/models';
import { adjustString, IsAdmin } from 'src/utilities';
import DeleteIcon from '@mui/icons-material/Delete';
import SettingsBackupRestoreIcon from '@mui/icons-material/SettingsBackupRestore';
import { FormattedDate, useIntl } from 'react-intl';

type HmyTableRowProjectProps = {
    isFirst: boolean;
    isLast: boolean;
    project: BasicProject;
    currentUser: CurrentUser;
    handleClickProject: (projectId: string) => void;
    setSelectedProject: (project: BasicProject) => void;
  };

const HmyTableRowProject: FC<HmyTableRowProjectProps> = ({isFirst, isLast, project, currentUser, handleClickProject, setSelectedProject}) => {
  
  const intl = useIntl();

  return (
    <HmyTableRow
      isFirst={isFirst}
      isLast={isLast}
    >
      <Box
        className="grouping-file-table-row-element"
        style={{width: "10%", justifyContent: "center"}}
      >
        <FormattedDate year="numeric" month="2-digit" day="2-digit" value={project.date} />
      </Box>  
      <Box
        className={project.isClosed ? "projects-table-row-element isClosed" : (project.isDeleted ? "projects-table-row-element isDeleted" : "projects-table-row-element")}
        style={{width: "10%", justifyContent: "center", cursor: "pointer"}}
        onClick={() => handleClickProject(project.projectId)}
      >
        {IsAdmin(currentUser) ? `${project.sourceId}-${project.projectNumber}` : project.projectNumber}
      </Box>  
      <Box
        className={project.isClosed ? "projects-table-row-element isClosed" : (project.isDeleted ? "projects-table-row-element isDeleted" : "projects-table-row-element")}
        style={{width: "35%", justifyContent: "flex-start", paddingLeft: 40, cursor: "pointer"}}
        onClick={() => handleClickProject(project.projectId)}
      >
        {adjustString(project.projectName ?? project.description, 35)}
      </Box>
      <Box
        className={project.isClosed ? "projects-table-row-element isClosed" : (project.isDeleted ? "projects-table-row-element isDeleted" : "projects-table-row-element")}
        style={{width: "30%", justifyContent: "center"}}
      >
        {currentUser.hmy
        ?                   
          <StringMultiple 
            elements={IsAdmin(currentUser) ? 
              (project.users.filter(x => x.profileName.toLowerCase() === "commercial" || x.profileName.toLowerCase() === "projectmanager")).map(x => `${x.fullName} - ${intl.formatMessage({id:x.profileName.toLowerCase()})}`)
              : (project.users.filter(x => x.profileName.toLowerCase() === "customer")).map(x => x.fullName)}
            cutString={0}
          />
        :
          (!currentUser.hmy
          ?
            project.users.filter(x => x.profileName.toLowerCase() === "commercial")[0]?.fullName
          :
            null
          )
      }
      </Box>
      <Box
        className={project.isClosed ? "projects-table-row-element isClosed" : (project.isDeleted ? "projects-table-row-element isDeleted" : "projects-table-row-element")}
        style={{width: "15%", justifyContent: "center"}}
      >
        {IsAdmin(currentUser)
        ?
          null
        :
          (project.isDeleted
            ?
            <SettingsBackupRestoreIcon
              className="delete-icon"
              onClick={() => setSelectedProject(project)}
            />
            :
            <DeleteIcon 
              className="delete-icon"
              onClick={() => setSelectedProject(project)}
            />)
        }          
      </Box>
    </HmyTableRow>
  )
}

export default HmyTableRowProject;