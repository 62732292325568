import './load-component.scss';
import { FC } from 'react';
import { Backdrop, Box, LinearProgress, Typography } from '@mui/material';

type LoadComponentProgressProps = {
  progress: number;
};

const LoadComponentProgress: FC<LoadComponentProgressProps> = ({progress}) => {
  return (
    <Backdrop 
      sx={{zIndex: 1000}}
      open={true}
    >
      <Box className="load-component-progress">
        <Box
          className="load-component-progress-bar"
        >
          <LinearProgress color="primary" variant="determinate" value={progress} />
        </Box>
        <Typography className="load-component-progress-text">{progress}%</Typography>
      </Box>
    </Backdrop>
  )
}

export {LoadComponentProgress};