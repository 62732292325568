import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { currentUserSelector } from 'src/redux/states';
import { SHOPPING_LIST } from 'src/services';
import { BasicCatalogueLineModelService, BasicShoppingOrder } from 'src/services/catalogue';
import { formatDate } from 'src/utilities';

export const EVENT_SHOPPING_LIST_UPDATED = 'localShoppingUpdate';

const EMPTY_SHOPPING_ORDER: BasicShoppingOrder = {
  projectId: '',
  phoneNumber: '',
  lines: [],
};

export function useShoppingList(projectId?: string) {
  const currentUser = useSelector(currentUserSelector);

  useEffect(() => {
    setPhoneNumber(currentUser.phoneNumber);
  }, [currentUser]);

  useEffect(() => {
    if (projectId) {
      setShoppingList((prevList) => ({ ...prevList, projectId }));
    }
  }, [projectId]);

  const [shoppingList, setShoppingList] = useState<BasicShoppingOrder>(() => {
    try {
      const item = sessionStorage.getItem(SHOPPING_LIST);
      return item ? JSON.parse(item) : { ...EMPTY_SHOPPING_ORDER, projectId, phoneNumber: currentUser.phoneNumber };
    } catch (error) {
      console.error(error);
      return [];
    }
  });

  useEffect(() => {
    try {
      sessionStorage.setItem(SHOPPING_LIST, JSON.stringify(shoppingList));
      document.dispatchEvent(new Event(EVENT_SHOPPING_LIST_UPDATED));
    } catch (error) {
      console.error(error);
    }
  }, [SHOPPING_LIST, shoppingList]);

  const addItem = (item: BasicCatalogueLineModelService) => {
    setShoppingList(() => {
      const prevList = getShoppingList();
      const newData = [...(prevList.lines ?? [])];
      const findIndex = newData.findIndex((x) => x.reference === item.reference && x.color === item.color);
      if (findIndex !== -1) {
        newData[findIndex] = item;
      } else {
        newData.push(item);
      }

      return { ...prevList, lines: newData };
    });
  };
  const addCatalogItem = (items: BasicCatalogueLineModelService[]) => {
    setShoppingList(() => {
      const prevList = getShoppingList();
      let newData = [...(prevList.lines ?? [])];
      items.forEach((item) => {
        const findIndex = newData.findIndex((x) => x.reference === item.reference && x.color === item.color);
        if (findIndex !== -1) {
          newData[findIndex] = { ...item, quantity: newData[findIndex].quantity + item.quantity };
        } else {
          newData.push(item);
        }
      });

      return { ...prevList, lines: newData };
    });
  };

  const removeItem = (reference: string, color: string) => {
    setShoppingList(() => {
      const prevList = getShoppingList();
      return {
        ...prevList,
        lines: prevList.lines.filter(
          (shoppingItem: BasicCatalogueLineModelService) =>
            !(shoppingItem.reference === reference && shoppingItem.color === color)
        ),
      };
    });
  };

  const getPhoneNumber = () => {
    const item = sessionStorage.getItem(SHOPPING_LIST);
    return item ? JSON.parse(item).phoneNumber : '';
  };

  const setPhoneNumber = (phoneNumber: string) => {
    const shoppingList = getShoppingList();
    setShoppingList({ ...shoppingList, phoneNumber });
  };

  const getShoppingList = () => {
    const item = sessionStorage.getItem(SHOPPING_LIST);
    return item ? JSON.parse(item) : null;
  };

  const clearList = () => {
    //setShoppingList(EMPTY_SHOPPING_ORDER);
    sessionStorage.removeItem(SHOPPING_LIST);
    document.dispatchEvent(new Event(EVENT_SHOPPING_LIST_UPDATED));
  };

  return {
    shoppingList,
    addItem,
    removeItem,
    clearList,
    addCatalogItem,
    getPhoneNumber,
    setPhoneNumber,
    getShoppingList,
  };
}
