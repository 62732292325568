import './hmy-btn.scss';
import { FC } from 'react';
import { Box, useMediaQuery } from '@mui/material';
import { FormattedMessage } from 'react-intl';

type HmyBtnProps = {
  title: string; //Esto tiene que ser el id de la traducción
  width: number;
  fullwith: boolean;
  color: 'primary' | 'secondary' | 'errorpage';
  filled?: boolean;
  disabled?: boolean;
  handleClick: () => void;
};

const HmyBtn: FC<HmyBtnProps> = ({ title, width, fullwith, color, filled, disabled, handleClick }) => {
  const matches = useMediaQuery('(max-width: 768px)');

  let className = [color === 'primary' ? 'hmy-btn' : color === 'secondary' ? 'hmy-btn-secondary' : 'hmy-btn-errorpage'];
  if (filled) {
    className.push('filled');
  }
  if (disabled) {
    className.push('disabled');
  }
  return (
    <Box
      onClick={() => handleClick()}
      className={className.join(' ')}
      style={{ width: fullwith ? (matches ? 'calc(100% - 24px)' : '100%') : width > 0 ? `${width}px` : 'auto' }}
    >
      <FormattedMessage id={title} />
    </Box>
  );
};

export { HmyBtn };
