import './inbox-file-add.scss';
import { FC, useEffect, useState } from 'react'
import { Box } from '@mui/material';
import { HmyForm, HmyInboxFileZone, HMY_INPUT_FILE_ID, LoadComponent, LoadComponentProgress } from 'src/components';
import { DocumentProps, ErrorMessage, SuccessMessage } from 'src/models';
import { useDispatch } from 'react-redux';
import { useFetchAndLoad } from 'src/hooks';
import { blobServices } from 'src/services';
import { setMessage } from 'src/redux/states';
import { formatDate } from 'src/utilities';
import InboxFileAddForm, { InboxNewFileForm } from './inbox-file-add-form';

type InboxFileAddProps = {
  documentOptions: DocumentProps;
  createNewInboxBlob: (file: File, name: string, description: string, date: Date, documentType: string) => Promise<boolean>;
  createInboxLargeBlob: (name: string, validName: string, description: string, date: Date, documentType: string) => Promise<boolean>;
};

const InboxFileAdd: FC<InboxFileAddProps> = ({ documentOptions, createNewInboxBlob, createInboxLargeBlob }) => {

  const dispatch = useDispatch();

  const { loading, callEndpoint } = useFetchAndLoad();
  const chunkSize = 1048576 * 7;
  const maxSize = 1048576 * 10;
  const initialValues: InboxNewFileForm = {
    finalName: '',
    description: '',
    date: formatDate(new Date()),
    documentType: ''
  };

  const [file, setFile] = useState<File | null>(null);

  //LargeFile
  const [showProgress, setShowProgress] = useState<boolean>(false)
  const [counter, setCounter] = useState(1)
  const [beginingOfTheChunk, setBeginingOfTheChunk] = useState<number>(0)
  const [endOfTheChunk, setEndOfTheChunk] = useState<number>(chunkSize)
  const [progress, setProgress] = useState<number>(0)
  const [chunkCount, setChunkCount] = useState<number>(0);
  const [canSendFile, setCanSendFile] = useState<boolean>(false);
  const [values, setValues] = useState<InboxNewFileForm>(initialValues);

  const getValidName = async (fileName: string) => await callEndpoint(blobServices.getValidName(fileName));
  const uploadChunks = async (request: FormData) => await callEndpoint(blobServices.uploadChunks(request));

  const handleAddDocument = async () => {
      resetChunkProperties();
      try {
        const response = await getValidName(file!.name);
        if(response.status === 200){
          if(file!.size > maxSize){
            setValues({
              ...values,
              finalName: response.data
            })
            const _totalCount = file!.size % chunkSize === 0 ? file!.size / chunkSize : Math.floor(file!.size / chunkSize) + 1;
            setChunkCount(_totalCount)
            setCanSendFile(true);
          }
          else{
            try {
                await createNewInboxBlob(file!, response.data, values.description, new Date(values.date), values.documentType);
                setFile(null);
                setValues(initialValues);
            } catch (error) {
            }
          }    
        }
      } catch (error) {
      }
  }

  const resetChunkProperties = () => {
    setShowProgress(false)
    setProgress(0)
    setCounter(1)
    setBeginingOfTheChunk(0)
    setEndOfTheChunk(chunkSize)
  }

  //Large files
  const fileUpload = () => {
    setCounter(counter + 1);
    if (counter <= chunkCount) {
      let chunk = file!.slice(beginingOfTheChunk, endOfTheChunk);
      uploadChunk(chunk)
    }
  }

  const uploadChunk = async (chunk: Blob) => {

    const formData = new FormData();
    formData.append("id", counter.toString());
    formData.append("fileName", values.finalName);
    formData.append("formFile", chunk);

    try {
    await uploadChunks(formData);
    setBeginingOfTheChunk(endOfTheChunk);
    setEndOfTheChunk(endOfTheChunk + chunkSize);
    if (counter === chunkCount) {
        await uploadCompleted();
    } 
    else 
    {
        let percentage = (counter / chunkCount) * 100;
        setProgress(percentage);
    }
    } 
    catch (error) 
    {
      dispatch(setMessage(ErrorMessage("error.UploadingFile", true)));
      setShowProgress(false);
    }
  }
  
  /**
  * Completa el proceso de subida de un documento de más de 10mb al proyecto
  */
  const uploadCompleted = async () => {
    try {
      await createInboxLargeBlob(file!.name, values.finalName, values.description, new Date(values.date), values.documentType);
      setProgress(100);
      setCanSendFile(false);
      setFile(null);
      setShowProgress(false);
      setValues(initialValues);
      dispatch(setMessage(SuccessMessage("success.fileUploadedSuccessfully", true)));
    } 
    catch (error) 
    {
      setShowProgress(false);
      dispatch(setMessage(ErrorMessage("error.UploadingFile", true)));
    }
  }

  const handleResetForm = () => {
    setValues(initialValues);
  }

  const handleCancelForm = () => {
    setValues(initialValues);
    resetChunkProperties();
    setFile(null);
    (document.getElementById(HMY_INPUT_FILE_ID) as HTMLInputElement).value = "";
  }

  useEffect(() => {
    if (file && file?.size > 0 && canSendFile) {
        if(!showProgress){
            setShowProgress(true);
        }
        
      fileUpload();
    }
  }, [file, canSendFile, progress]);

  return (
    loading 
    ? 
      (canSendFile ? <LoadComponentProgress progress={progress} /> : <LoadComponent />)
    :
      <Box
        className="inbox-file-add-container"
      >
        <HmyInboxFileZone 
            documentProperties={documentOptions}
            file={file}
            setFile={setFile}
        />
        {file !== null
        ?
          <HmyForm
            submitFormDisabled={file === null || values.description === '' || values.documentType === ''}
            handleResetForm={handleResetForm}
            handleSubmitForm={handleAddDocument}
            handleCancelForm={handleCancelForm}
          >
            <InboxFileAddForm 
              documentTypes={documentOptions.documentTypes}
              values={values}
              setValues={setValues}
            />
          </HmyForm>
        :     
          null
        }
      </Box>
  )
}

export default InboxFileAdd;