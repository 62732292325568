import CommentIcon from '@mui/icons-material/Comment';
import { Box } from '@mui/material';
import { FC } from 'react';
import { FormattedDate } from 'react-intl';
import { useNavigate } from 'react-router';
import { HmyTableRow } from 'src/components';
import { SaleOrderIncidenceModel } from 'src/models/orders';
import { IncidenceStatusText } from '../incidence-status-text';
import './hmy-table-row-incidences.scss';

type HmyTableRowIncidencesProps = {
  isFirst: boolean;
  isLast: boolean;
  incidence: SaleOrderIncidenceModel;
};

const HmyTableRowIncidences: FC<HmyTableRowIncidencesProps> = ({ isFirst, isLast, incidence }) => {
  const navigate = useNavigate();

  const handleClickIncidence = () => {
    navigate(`/incidence/${incidence.incidenceId}`);
  };

  return (
    <HmyTableRow isFirst={isFirst} isLast={isLast}>
      <Box
        className="incidences-table-row-element"
        style={{ width: '30%', justifyContent: 'start', alignItems: 'center', paddingLeft: '10px', cursor: 'pointer' }}
        onClick={handleClickIncidence}
      >
        <Box style={{ width: '40px', justifyContent: 'start', alignItems: 'center', display: 'flex' }}>
          {incidence.unreadComments > 0 && <CommentIcon style={{ width: '20px', color: 'green' }} />}
        </Box>
        {incidence.number}
      </Box>
      <Box
        className="incidences-table-row-element"
        style={{ width: '30%', justifyContent: 'center', cursor: 'pointer' }}
        onClick={handleClickIncidence}
      >
        <FormattedDate year="numeric" month="2-digit" day="2-digit" value={incidence.date} />
      </Box>

      <Box
        className="incidences-table-row-element"
        style={{ width: '40%', justifyContent: 'center', cursor: 'pointer' }}
        onClick={handleClickIncidence}
      >
        <IncidenceStatusText statusNumber={incidence.statusNumber} />
      </Box>
    </HmyTableRow>
  );
};

export default HmyTableRowIncidences;
