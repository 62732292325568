import './hmy-selectable.scss';
import { FC } from 'react';
import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Selectable, SelectableDefaultValue } from 'src/models';

type HmySelectableProps = {
    title: string; //Esto tiene que ser el id de la traducción
    defaultValue: string;
    elements: Selectable[];
    value: string;
    isFormattedValue: boolean;
    setValue: (value: string) => void;
  };

const HmySelectable: FC<HmySelectableProps> = ({title, defaultValue, elements, value, isFormattedValue, setValue}) => {
    const matches = useMediaQuery('(max-width: 768px)');

    const handleChange = (event: SelectChangeEvent) => {
        setValue(event.target.value as string);
    };

  return (
    <FormControl fullWidth color="primary" sx={{backgroundColor: "white", borderRadius: "50px"}}>
      <InputLabel id="hmy-select-label" color="primary"><FormattedMessage id={title} /></InputLabel>
      <Select
        labelId="hmy-select-label"
        id="hmy-select"
        value={value}
        label={<FormattedMessage id={title} />}
        onChange={handleChange}
        className="hmy-selectable"
        sx={{borderRadius: "50px"}}
        size={matches ? "small" : "medium"}
        color="primary"
      >
        {defaultValue !==""
          ?
            <MenuItem value={SelectableDefaultValue}><FormattedMessage id={defaultValue} /></MenuItem>
          :
            null
        }      
        {elements.map((element, index) => (
            <MenuItem 
            key={element.id}
            value={element.id}
            >
                {isFormattedValue ? <FormattedMessage id={element.text} /> : element.text}
            </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export { HmySelectable };