import './hmy-inbox-file-zone.scss';
import { FC, useCallback, useState } from 'react';
import { DocumentProps, ErrorMessage } from 'src/models';
import { Box, Typography, useMediaQuery } from '@mui/material';
import { useDispatch } from 'react-redux';
import { setMessage } from 'src/redux/states';
import { HmyInputFile, HMY_INPUT_FILE_ID } from '../hmy-input-file';
import { HmyDropZone } from '../hmy-dropzone';
import { FormattedMessage } from 'react-intl';
import InputIcon from '@mui/icons-material/Input';
import DeleteIcon from '@mui/icons-material/Delete';

type HmyInboxFileZoneProps = {
    documentProperties: DocumentProps;
    file: File | null;
    setFile: (value: File | null) => void;
};

const HmyInboxFileZone: FC<HmyInboxFileZoneProps> = ({ documentProperties, file, setFile }) => {

    const dispatch = useDispatch();
    const [isDropActive, setIsDropActive] = useState<boolean>(false);
    const matches = useMediaQuery('(max-width: 768px)');
    
    // Create handler for dropzone's onDragStateChange:
    const onDragStateChange = useCallback((dragActive: boolean) => {
        setIsDropActive(dragActive)
    }, []);

    const validateFile = (newFile: File) : boolean => {    
        const arr = newFile.name.split(".");
        const lastPosition = arr[arr.length - 1];
        if(documentProperties.extensions.findIndex(x => x.toLowerCase() === lastPosition.toLowerCase()) === -1)
        {
            dispatch(setMessage(ErrorMessage("error.documentType", true)));
            (document.getElementById("hmy-input-file") as HTMLInputElement).value = "";
            return false;
        }
        else if(newFile.size >  (documentProperties.maxSize * 1024 * 1024))
        {
            dispatch(setMessage(ErrorMessage("error.documentSize", true)));
            (document.getElementById("hmy-input-file") as HTMLInputElement).value = "";
            return false;
        }
        else
        {
            return true;
        }
    }

    // Create handler for dropzone's onFilesDrop:
    const onFilesDrop = useCallback((files: File[]) => {
        handleSetFile(files);
    }, []);

    const handleSetFile = (files: File[]) => {
        if(validateFile(files[0]))
        {
            setFile(files[0]);
            setIsDropActive(false);
        }
    }

    const handleDeleteFile = () => {
      setFile(null);
      //Vaciamos el valor del input
      (document.getElementById(HMY_INPUT_FILE_ID) as HTMLInputElement).value = "";
    }

    return (
        <Box
            className="hmy-inbox-file-zone"
        >
            <Box
                className={isDropActive ? "hmy-inbox-file-zone-container active" : "hmy-inbox-file-zone-container"}
            >
                {file === null
                ?
                    <Box
                        className="hmy-dropzone-container"
                    >
                        {matches
                            ?
                            <Box className="hmy-inbox-container-dropzone-btn">
                                <HmyInputFile 
                                    title="chooseOnDevice"
                                    width={250}
                                    setFile={handleSetFile}
                                />
                            </Box>
                            :
                            <HmyDropZone 
                                onDragStateChange={onDragStateChange} 
                                onFilesDrop={onFilesDrop}
                            >
                                <Typography className="hmy-inbox-container-dropzone-title">
                                    <FormattedMessage id="dragDropFile" /> <InputIcon />
                                </Typography>
                                <Typography className="hmy-inbox-container-dropzone-text">
                                    <FormattedMessage id="or" />
                                </Typography>
                                <Box className="hmy-inbox-container-dropzone-btn">
                                    <HmyInputFile 
                                        title="chooseOnDevice"
                                        width={300}
                                        setFile={handleSetFile}
                                    />
                                </Box>
                            </HmyDropZone>
                        }
                    </Box>
                :
                    <Box
                        className="hmy-inbox-file-zone-selected"
                    >
                        <Typography className="hmy-inbox-container-dropzone-text">
                            {file.name}
                        </Typography>
                        <DeleteIcon 
                            onClick={() => handleDeleteFile()}
                            color="error"
                            style={{cursor: "pointer"}}
                            fontSize="inherit"
                        />
                    </Box>
                }
                  
            </Box>
            <Box>
                <Typography className="hmy-inbox-file-zone-info"><FormattedMessage id="documentInfo" />{` ${documentProperties.maxSize}mb`}</Typography>
            </Box>
            <Box>
                <Typography className="hmy-inbox-file-zone-info"><FormattedMessage id="documentInfo2" />{` ${documentProperties.extensions.join(", ")}`}</Typography>
            </Box>
        </Box>
    )
}

export { HmyInboxFileZone };