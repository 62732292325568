import { useMatomo } from '@jonkoops/matomo-tracker-react';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import CloseIcon from '@mui/icons-material/Close';
import NorthIcon from '@mui/icons-material/North';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import SouthIcon from '@mui/icons-material/South';
import { Box, Fab, Link } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { FC, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { HmyBtn, HmyInfiniteScroll, HmyTable, LoadComponent, ROUTE_NEW_ORDER, ROUTE_ORDER } from 'src/components';
import { HmyBtnHelp } from 'src/components/hmy-btn-help';
import { HmyCalendarFilter } from 'src/components/hmy-calendar-filter';
import { HmySelectableAutocomplete } from 'src/components/hmy-selectable-autocomplete';
import { GetOrdersDto } from 'src/dtos/orders';
import { useFetchAndLoad } from 'src/hooks';
import { useOrderStates } from 'src/hooks/use-order-states.hook';
import { CompleteProject, GeneralCollection, Selectable, TableHeaderElement } from 'src/models';
import { BasicOrder } from 'src/models/orders';
import { ADD_ORDERS } from 'src/permissions/permissions';
import { currentUserSelector } from 'src/redux/states';
import { BasicProjectModelService, projectServices } from 'src/services';
import { OrderStateModelService, orderServices } from 'src/services/orders';
import { IsCustomer, formatDate } from 'src/utilities';
import CAN from '../../permissions';
import { downloadExcel } from './download-excel-helpers';
import HmyCardOrder from './hmy-card-order';
import HmyTableRowOrder from './hmy-table-row-order';
import NoOrders from './no-orders';
import './orders.scss';

type OrdersProps = {
  project?: CompleteProject;
};
const TODAY = new Date();
const SEARCH_START_DATE = new Date(TODAY.getTime() - 30 * 24 * 60 * 60 * 1000);
const EMPTY_PAGINATION_REQUEST = {
  page: 0,
  columnOrder: 'Number',
  typeOrder: 'DESC',
  filter: '',
  saleOrderStatusId: '',
  startDate: '',
  endDate: '',
};

const Orders: FC<OrdersProps> = ({ project }) => {
  const intl = useIntl();
  const navigate = useNavigate();
  const matches = useMediaQuery('(max-width: 768px)');
  const [searchParams, setSearchParams] = useSearchParams();
  const currentUser = useSelector(currentUserSelector);
  const { trackPageView, pushInstruction } = useMatomo();
  const { loading, callEndpoint } = useFetchAndLoad();
  const [loadingOrders, setLoadingOrders] = useState<boolean>(false);
  const [selectedOrders, setSelectedOrders] = useState<BasicOrder[]>([]);
  const [orders, setOrders] = useState<GeneralCollection<BasicOrder>>();
  const [projects, setProjects] = useState<Selectable[]>([]);
  const [searchText, setSearchText] = useState<string>('');
  const [searchProject, setSearchProject] = useState<string>(project ? project.projectId : '');
  const [searchState, setSearchState] = useState<string>('');
  const [searchStartDate, setSearchStartDate] = useState<Date>(SEARCH_START_DATE);
  const [searchEndDate, setSearchEndDate] = useState<Date>(TODAY);
  const [paginatorRequest, setPaginatorRequest] = useState<GetOrdersDto>({
    ...EMPTY_PAGINATION_REQUEST,
    take: matches ? 10 : 5,
    projectId: project ? project.projectId : '',
  });

  const { data: listStates } = useOrderStates();

  const orderNumber = intl.formatMessage({ id: 'orderNumber' });
  const projectId = intl.formatMessage({ id: 'project' });
  const articlesQty = intl.formatMessage({ id: 'articlesQty' });
  const date = intl.formatMessage({ id: 'date' });
  const status = intl.formatMessage({ id: 'status' });
  const options = intl.formatMessage({ id: 'options' });

  const handleChangeOrder = () => {
    const order: string = paginatorRequest.typeOrder === 'DESC' ? 'ASC' : 'DESC';
    setSearchParams({ ...searchParams, page: '1', take: paginatorRequest.take.toString(), typeOrder: order });
  };

  const headerELementList: TableHeaderElement[] = [
    {
      element: (
        <Box sx={{ display: 'flex', flexDirection: 'row', gap: '5px', alignItems: 'center' }}>
          <p>{orderNumber}</p>
          {paginatorRequest.typeOrder.toLowerCase().includes('asc') ? (
            <NorthIcon fontSize="inherit" />
          ) : (
            <SouthIcon fontSize="inherit" />
          )}
        </Box>
      ),
      width: 15,
      withBorder: true,
      align: 'center',
      onClick: handleChangeOrder,
    },
    {
      element: articlesQty,
      width: 10,
      withBorder: true,
      align: 'center',
      onClick: null,
    },
    {
      element: projectId,
      width: 20,
      withBorder: true,
      align: 'center',
      onClick: null,
    },
    {
      element: date,
      width: 15,
      withBorder: true,
      align: 'center',
      onClick: null,
    },
    {
      element: status,
      width: 15,
      withBorder: true,
      align: 'center',
      onClick: null,
    },
    {
      element: options,
      width: 20,
      withBorder: false,
      align: 'center',
      onClick: null,
    },
  ];

  const getListOrders = async () => await callEndpoint(orderServices.getOrders(paginatorRequest));

  const getListProjects = async () =>
    await callEndpoint(
      projectServices.getFilterProjects({ filter: '', showClosedProjects: false, showDeletedProjects: false })
    );

  const adaptListOrders = (data: GeneralCollection<BasicOrder>) => {
    let listProject: BasicOrder[] = [];
    let newProjects = data.items;

    if (matches && paginatorRequest.page !== 1) {
      listProject = (orders?.items ?? []).concat(newProjects);
    } else {
      listProject = newProjects;
    }

    setOrders({
      items: listProject,
      total: data.total,
      currentPage: data.currentPage,
      pages: data.pages,
    });
  };

  const handleEnterSearch = () => {
    setSearchParams({
      ...searchParams,
      filter: searchText,
      page: '1',
      take: paginatorRequest.take.toString(),
      typeOrder: paginatorRequest.typeOrder,
    });
  };

  const cleanFilters = () => {
    setSearchText('');
    setSearchProject(project ? project.projectId : '');
    setSearchState('');
    setSearchStartDate(SEARCH_START_DATE);
    setSearchEndDate(TODAY);
    setPaginatorRequest({
      ...EMPTY_PAGINATION_REQUEST,
      take: matches ? 10 : 5,
      projectId: project ? project.projectId : '',
      page: 1,
    });
  };

  const handleClickOrder = (projectId: string, orderId: string) => {
    navigate(`${ROUTE_ORDER.replace(':orderid', orderId)}`);
  };

  const handleNewOrder = () => {
    if (project) {
      navigate(`${ROUTE_NEW_ORDER.replace(':projectid', project.projectId)}`);
    } else if (searchProject) {
      navigate(`${ROUTE_NEW_ORDER.replace(':projectid', searchProject)}`);
    }
  };

  const handleChangePage = (page: number) => {
    setSearchParams({
      ...searchParams,
      page: page.toString(),
      take: paginatorRequest.take.toString(),
      typeOrder: paginatorRequest.typeOrder,
    });
  };

  const handleChangeRowsPerPage = (pages: number) => {
    setSearchParams({ ...searchParams, page: '1', take: pages.toString(), typeOrder: paginatorRequest.typeOrder });
  };

  /**
   * Evento para marcar/desmarcar todas las líneas
   */
  const handleCheck = (value: boolean) => {
    if (value && orders) {
      setSelectedOrders(orders.items);
    } else if (!value) {
      setSelectedOrders([]);
    }
  };

  /**
   * Evento para marcar/desmarcar una línea
   */
  const handleCheckRow = (saleOrderId: string) => {
    if (selectedOrders.findIndex((x) => x.saleOrderId === saleOrderId) > -1) {
      const newOrders = selectedOrders.filter((x) => x.saleOrderId !== saleOrderId);
      setSelectedOrders(newOrders ?? []);
    } else {
      let newOrders = [...selectedOrders];
      const newOrder = orders?.items.find((x) => x.saleOrderId === saleOrderId);
      if (newOrder) {
        newOrders.push(newOrder);
        setSelectedOrders(newOrders);
      }
    }
  };

  /**
   * Evento para descargar excel de pedidos seleccionados
   */
  const handleDownloadExcel = () => {
    if (selectedOrders.length > 0) {
      callEndpoint(
        orderServices.postOrdersExcel({ saleOrderIds: selectedOrders.map((order) => order.saleOrderId) })
      ).then((response) => {
        response && downloadExcel(response.data, `Orders`);
      });
    }
  };

  useEffect(() => {
    setSearchText(searchParams.get('filter') ?? '');
    setPaginatorRequest({
      ...paginatorRequest,
      filter: searchParams.get('filter') ?? '',
      projectId: searchProject,
      saleOrderStatusId: searchState,
      startDate: formatDate(searchStartDate),
      endDate: formatDate(searchEndDate),
      page: Number(searchParams.get('page') ?? 1),
      take: Number(searchParams.get('take') ?? paginatorRequest.take),
      typeOrder: searchParams.get('typeOrder') ?? paginatorRequest.typeOrder,
      columnOrder: 'Number',
    });
  }, [searchParams, searchProject, searchState, searchStartDate, searchEndDate]);

  useEffect(() => {
    if (paginatorRequest.page > 0) {
      setLoadingOrders(true);
      getListOrders()
        .then((response) => {
          adaptListOrders(response.data);
          setLoadingOrders(false);
        })
        .catch((error) => {});
    }
  }, [paginatorRequest]);

  useEffect(() => {
    pushInstruction('setUserId', currentUser.email);

    if (!project) {
      getListProjects()
        .then((response: { data: GeneralCollection<BasicProjectModelService> }) =>
          setProjects(
            response.data.items.map((project) => ({
              id: project.projectId,
              text: project.projectName ?? project.description,
            }))
          )
        )
        .catch((error) => {});
    }

    trackPageView({
      documentTitle: 'Orders',
    });
  }, []);

  return (
    <Box className={project ? 'orders-container' : 'orders-container orders-container-full'}>
      <Box className="orders-main">
        {!loading && !loadingOrders ? (
          <>
            <Box className="orders-filters">
              <Box className="orders-filters-container">
                {!project && (
                  <HmySelectableAutocomplete
                    value={searchProject}
                    elements={projects}
                    setValue={setSearchProject}
                    isFormattedValue={false}
                    defaultValue=""
                    title="project"
                  />
                )}
                <HmySelectableAutocomplete
                  value={searchState}
                  elements={
                    listStates?.map((state: OrderStateModelService) => ({
                      id: state.saleOrderStatusId,
                      text: `status-${state.number}`,
                    })) || []
                  }
                  setValue={setSearchState}
                  isFormattedValue={true}
                  defaultValue=""
                  title="status"
                />
                <HmyCalendarFilter
                  position="left"
                  date={searchStartDate}
                  setDate={setSearchStartDate}
                  title="dateFrom"
                />

                <HmyCalendarFilter position="right" date={searchEndDate} setDate={setSearchEndDate} title="dateTo" />
                <div className="hmy-modal-order-filters-search" onClick={() => {}}>
                  <input
                    value={searchText}
                    onChange={(e) => setSearchText(e.target.value)}
                    placeholder={'Buscar pedido'}
                  />
                  <div onClick={handleEnterSearch} style={{ cursor: 'pointer' }}>
                    <SearchOutlinedIcon style={{ fontSize: '30px' }} />
                  </div>
                </div>
              </Box>
              <div className="orders-filters-buttons">
                <div onClick={cleanFilters} className="orders-filters-buttons-clear">
                  <CloseIcon style={{ fontSize: '14px' }} />
                  <FormattedMessage id="cleanFilters" />
                </div>
                {CAN(ADD_ORDERS) && (
                  <HmyBtn
                    title="addNewOrder"
                    width={230}
                    fullwith={false}
                    color="secondary"
                    handleClick={handleNewOrder}
                    filled
                    disabled={!project && !searchProject}
                  />
                )}
                {project && <HmyBtnHelp projectId={project.projectId} />}
              </div>
            </Box>
            <Box className="orders-switch-container"></Box>
            {(!orders || orders?.items.length === 0) && paginatorRequest.filter === '' ? (
              <NoOrders />
            ) : (
              <>
                {!IsCustomer(currentUser) && (
                  <Box style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
                    <HmyBtn
                      title="exportResults"
                      color="primary"
                      filled
                      handleClick={handleDownloadExcel}
                      fullwith={false}
                      width={300}
                      disabled={selectedOrders.length === 0}
                    />
                  </Box>
                )}
                <HmyTable
                  headerELementList={headerELementList}
                  currentPage={orders?.currentPage ?? 1}
                  pages={orders?.pages ?? 0}
                  rowsPerPage={paginatorRequest.take}
                  isChekable={true}
                  isChecked={selectedOrders.length === orders?.items.length}
                  handleCheck={handleCheck}
                  handleChangePage={handleChangePage}
                  handleChangeRowsPerPage={handleChangeRowsPerPage}
                >
                  {loading ? (
                    <LoadComponent />
                  ) : (
                    orders?.items.map((order, index) => (
                      <HmyTableRowOrder
                        key={`${order.saleOrderId}-${index}`}
                        isFirst={index === 0}
                        isLast={index === orders?.items.length - 1}
                        order={order}
                        currentUser={currentUser}
                        handleClickOrder={handleClickOrder}
                        handleCheckRow={handleCheckRow}
                        selectedOrders={selectedOrders}
                      />
                    ))
                  )}
                </HmyTable>
                {matches ? (
                  <HmyInfiniteScroll
                    currentPage={orders?.currentPage ?? 1}
                    pages={orders?.pages ?? 0}
                    dataLength={orders?.items.length ?? 0}
                    handleChangePage={handleChangePage}
                  >
                    {loading ? (
                      <LoadComponent />
                    ) : (
                      orders?.items.map((order, index) => (
                        <HmyCardOrder
                          key={`${order.saleOrderId}-${index}`}
                          order={order}
                          currentUser={currentUser}
                          handleClickOrder={handleClickOrder}
                          handleCheckRow={handleCheckRow}
                          selectedOrders={selectedOrders}
                        />
                      ))
                    )}
                  </HmyInfiniteScroll>
                ) : null}
              </>
            )}
          </>
        ) : null}
      </Box>

      <Fab className="orders-btn-up">
        <Link href="#">
          <ArrowUpwardIcon sx={{ fontSize: '40px' }} />
        </Link>
      </Fab>
    </Box>
  );
};

export default Orders;
