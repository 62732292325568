import './grouping-file-add.scss';
import { FC, useEffect, useState } from 'react'
import { Box, Typography } from '@mui/material';
import { FormattedMessage, useIntl } from 'react-intl';
import { HmyForm, HmyInboxFileZone, HMY_INPUT_FILE_ID, LoadComponent, LoadComponentProgress } from 'src/components';
import { DocumentProps, ErrorMessage, SuccessMessage } from 'src/models';
import { getBase64 } from 'src/utilities/get-base64';
import { CreateGroupingFileDto, CreateLargeGroupingFileDto } from 'src/dtos';
import { useDispatch } from 'react-redux';
import { useFetchAndLoad } from 'src/hooks';
import { blobServices, groupingServices } from 'src/services';
import { setMessage } from 'src/redux/states';
import { formatDate } from 'src/utilities';
import GroupingFileAddForm, { GroupingNewFileForm } from './grouping-file-add-form';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

type GroupingFileAddProps = {
  userGroupingId: string;
  documentOptions: DocumentProps;
  goBack: () => void;
};

const GroupingFileAdd: FC<GroupingFileAddProps> = ({ userGroupingId, documentOptions, goBack }) => {

  const dispatch = useDispatch();
  const intl = useIntl();

  const newElement = intl.formatMessage({id: "new"});
  const { loading, callEndpoint } = useFetchAndLoad();
  const chunkSize = 1048576 * 7;
  const maxSize = 1048576 * 10;
  const initialValues: GroupingNewFileForm = {
    finalName: '',
    nameDescription: '',
    type: '',
    category: '',
    tags: [],
    date: formatDate(new Date()),
    description: ''
  };

  const [file, setFile] = useState<File | null>(null);

  //LargeFile
  const [showProgress, setShowProgress] = useState<boolean>(false)
  const [counter, setCounter] = useState(1)
  const [beginingOfTheChunk, setBeginingOfTheChunk] = useState<number>(0)
  const [endOfTheChunk, setEndOfTheChunk] = useState<number>(chunkSize)
  const [progress, setProgress] = useState<number>(0)
  const [chunkCount, setChunkCount] = useState<number>(0);
  const [canSendFile, setCanSendFile] = useState<boolean>(false);
  const [values, setValues] = useState<GroupingNewFileForm>(initialValues);

  const getValidName = async (fileName: string) => await callEndpoint(blobServices.getValidName(fileName));
  const uploadChunks = async (request: FormData) => await callEndpoint(blobServices.uploadChunks(request));
  const createInboxBlob = async (request: CreateGroupingFileDto) => await callEndpoint(groupingServices.createGroupingBlob(request));
  const createInboxLargeBlob = async (request: CreateLargeGroupingFileDto) => await callEndpoint(groupingServices.createGroupingLargeBlob(request));

  const handleAddDocument = async () => {
    if(values.category === "" || values.type === "")
    {
      dispatch(setMessage(ErrorMessage("error.typeCategoryRequired", true)));
    }
    else
    {
      resetChunkProperties();
      try {
        const response = await getValidName(file!.name);
        if(response.status === 200){
          if(file!.size > maxSize){
            setValues({
              ...values,
              finalName: response.data
            })
            const _totalCount = file!.size % chunkSize == 0 ? file!.size / chunkSize : Math.floor(file!.size / chunkSize) + 1;
            setChunkCount(_totalCount)
            setCanSendFile(true);
          }
          else{
            try {
                await createNewInboxBlob();
                setFile(null);
            } catch (error) {
            }
          }    
        }
      } catch (error) {
      }
    }
  }

  const resetChunkProperties = () => {
    setShowProgress(false)
    setProgress(0)
    setCounter(1)
    setBeginingOfTheChunk(0)
    setEndOfTheChunk(chunkSize)
  }

  //Large files
  const fileUpload = () => {
    setCounter(counter + 1);
    if (counter <= chunkCount) {
      let chunk = file!.slice(beginingOfTheChunk, endOfTheChunk);
      uploadChunk(chunk)
    }
  }

  const uploadChunk = async (chunk: Blob) => {

    const formData = new FormData();
    formData.append("id", counter.toString());
    formData.append("fileName", values.finalName);
    formData.append("formFile", chunk);

    try {
    await uploadChunks(formData);
    setBeginingOfTheChunk(endOfTheChunk);
    setEndOfTheChunk(endOfTheChunk + chunkSize);
    if (counter == chunkCount) {
        await uploadCompleted();
    } 
    else 
    {
        let percentage = (counter / chunkCount) * 100;
        setProgress(percentage);
    }
    } 
    catch (error) 
    {
    dispatch(setMessage(ErrorMessage("error.UploadingFile", true)));
    setShowProgress(false);
    }
  }

  const adaptNewString = (value: string) : string => {
    return value.includes(`(${newElement})`) ? value.replace(`(${newElement})`, "").trimEnd() : value;
  }

  /**
  * Sube un nuevo documento menor de 10mb al proyecto
  */
  const createNewInboxBlob = async () => {
    const newTags = values.tags.map(x => adaptNewString(x));
    const base64 = await getBase64(file!);
    let definitiveRequest : CreateGroupingFileDto = {
      userGroupingId: userGroupingId,
      originalName: file?.name ?? "",
      name: values.nameDescription,
      type: adaptNewString(values.type),
      category: adaptNewString(values.category),
      tags: newTags,
      date: values.date,
      description: values.description,
      data: (base64 as string).split(',')[1]
    };
    try {
      await createInboxBlob(definitiveRequest);
      goBack();
      dispatch(setMessage(SuccessMessage("success.fileUploadedSuccessfully", true)));
    } 
    catch (error) {
      dispatch(setMessage(ErrorMessage("error.UploadingFile", true)));
    }
  }
  
  /**
  * Completa el proceso de subida de un documento de más de 10mb al proyecto
  */
  const uploadCompleted = async () => {
    const newTags = values.tags.map(x => adaptNewString(x));
    const request : CreateLargeGroupingFileDto = {
      userGroupingId: userGroupingId,
      originalName: file!.name,
      name: values.finalName,
      nameDescription: values.nameDescription,
      type: adaptNewString(values.type),
      category: adaptNewString(values.category),
      tags: newTags,
      date: values.date,
      description: values.description
    }

    try {
      await createInboxLargeBlob(request);
      setProgress(100);
      setCanSendFile(false);
      setFile(null);
      setShowProgress(false);
      goBack();
      dispatch(setMessage(SuccessMessage("success.fileUploadedSuccessfully", true)));
    } 
    catch (error) 
    {
      setShowProgress(false);
      dispatch(setMessage(ErrorMessage("error.UploadingFile", true)));
    }
  }

  const handleResetForm = () => {
    resetChunkProperties();
    setFile(null);
    (document.getElementById(HMY_INPUT_FILE_ID) as HTMLInputElement).value = "";
    setValues(initialValues);
  }

  useEffect(() => {
    if (file && file?.size > 0 && canSendFile) {
        if(!showProgress){
            setShowProgress(true);
        }
        
      fileUpload();
    }
  }, [file, canSendFile, progress]);

  return (
    loading 
    ? 
      (canSendFile ? <LoadComponentProgress progress={progress} /> : <LoadComponent />)
    :
      <Box
        className="grouping-file-add-container"
      >
        <Box
          className="grouping-file-title"
          onClick={() => goBack()}
        >
          <ChevronLeftIcon /> 
          <Typography
            className="grouping-file-new-title"
          >
            <FormattedMessage id="userGroupingAddFile" />     
          </Typography>
        </Box>
        <HmyInboxFileZone 
            documentProperties={documentOptions}
            file={file}
            setFile={setFile}
        />
        {file !== null
        ?
          <HmyForm
            submitFormDisabled={file === null || values.nameDescription === '' || values.type === '' || values.category === ''}
            handleResetForm={handleResetForm}
            handleSubmitForm={handleAddDocument}
            handleCancelForm={goBack}
          >
            <GroupingFileAddForm 
              userGroupingId={userGroupingId}
              values={values}
              setValues={setValues}
            />
          </HmyForm>
        :     
          null
        }
      </Box>
  )
}

export default GroupingFileAdd;