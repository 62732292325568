import { Box, Typography, useMediaQuery } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { FaTruckFast } from 'react-icons/fa6';
import { FormattedDate, FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { HmySelectable } from 'src/components';
import { HmyStatusText } from 'src/components/hmy-status-text';
import { useFetchAndLoad } from 'src/hooks';
import { useTransport } from 'src/hooks/use-transport.hook';
import { Selectable } from 'src/models';
import { BasicOrder } from 'src/models/orders';
import { currentUserSelector } from 'src/redux/states';
import { OrderStateModelService, orderServices } from 'src/services/orders';
import { formatDate } from 'src/utilities';
import '../order.scss';

type OrderHeaderProps = {
  orderData: BasicOrder;
  editable: boolean;
  currentState: string;
  setCurrentState: (statusId: string) => void;
  orderEdited: boolean;
};

const OrderHeader: FC<OrderHeaderProps> = ({ orderData, editable, currentState, setCurrentState, orderEdited }) => {
  const [orderStates, setOrderStates] = useState<Selectable[]>([]);
  const [deliveryDateView, setDeliveryDateView] = useState(new Date());
  const currentUser = useSelector(currentUserSelector);
  const { callEndpoint } = useFetchAndLoad();
  const matches = useMediaQuery('(max-width: 768px)');
  const getListStates = async () => await callEndpoint(orderServices.getOrderStates());
  const { deliveryDate } = useTransport(orderData);

  useEffect(() => {
    getListStates().then((response: { data: OrderStateModelService[] }) =>
      setOrderStates(
        response.data.map((state) => ({
          id: state.saleOrderStatusId,
          text: `status-${state.number}`,
        }))
      )
    );
  }, []);

  useEffect(() => {
    setDeliveryDateView(orderEdited ? deliveryDate : orderData.deliveryDate);
  }, [orderEdited, deliveryDate]);

  return (
    <>
      <Typography className="order-title-text">
        <FormattedMessage id="generalInformation" />
      </Typography>
      <Box className="order-header">
        <div>
          <div style={{ display: 'flex', columnGap: '14px', alignItems: 'center' }}>
            {orderData.urgent && <FaTruckFast />}
            {orderData.number && <span>{orderData.number}</span>}
          </div>
          <span>{orderData.projectName}</span>
        </div>
        {orderData.statusName && (
          <div className="order-header-inline">
            {editable ? (
              <HmySelectable
                value={currentState}
                elements={orderStates}
                setValue={setCurrentState}
                isFormattedValue={true}
                defaultValue=""
                title="status"
              />
            ) : (
              <>
                <FormattedMessage id="status" />:
                <HmyStatusText id={orderData.statusId} />
              </>
            )}
          </div>
        )}
        <div>
          <span style={{ display: 'flex', columnGap: '4px' }}>
            <span>
              <FormattedMessage id="purchaseDate" />
            </span>
            <span>
              <FormattedDate value={orderData.date ? formatDate(new Date(orderData.date)) : formatDate(new Date())} />
            </span>
          </span>
          <span style={{ display: 'flex', columnGap: '4px' }}>
            <span>
              <FormattedMessage id="deliveryDate" />
            </span>
            <span>
              <FormattedDate value={deliveryDateView} />
            </span>
          </span>
        </div>
        <div className="order-header-inline">{/* <FormattedMessage id="deliveryAddress" /> {orderData.address} */}</div>
        {orderData.statusName === '' && (
          <div>
            <span>
              <FormattedMessage id="phone" />
              {`: ${currentUser.phoneNumber}`}
            </span>
            <span>
              <FormattedMessage id="email" />
              {`: ${currentUser.email}`}
            </span>
          </div>
        )}
      </Box>
    </>
  );
};

export default OrderHeader;
