import { CreateGroupingDto, CreateGroupingFileDto, CreateLargeGroupingFileDto, GetCategoriesDto, GetGroupingFilesDto, GetTagsDto, GetTypesDto, UpdateGroupingUsersDto } from 'src/dtos';
import { GeneralCollection, RequestCollection } from 'src/models';
import { EncodeGetURI } from 'src/utilities';
import {http} from '../http-client';
import { CompleteGroupingModelService, UserGroupingBaseModelService, UserGroupingCategoryModelService, UserGroupingFileModelService, UserGroupingTagModelService, UserGroupingTypeModelService } from './model-service';

const getGroupings = (request: RequestCollection) => {
    const controller = new AbortController();

    return { call: http.get<GeneralCollection<UserGroupingBaseModelService>>(EncodeGetURI('userGrouping', request), { signal: controller.signal }), controller };
}

const getGrouping = (groupingId: string) => {
    const controller = new AbortController();

    return { call: http.get<GeneralCollection<CompleteGroupingModelService>>(`userGrouping/${groupingId}`, { signal: controller.signal }), controller };
}

const createGrouping = (request: CreateGroupingDto) => {
    const controller = new AbortController();

    return { call: http.post<CreateGroupingDto>('userGrouping', request, { signal: controller.signal }), controller };
}

const updateGroupingName = (id: string, name: string) => {
    const controller = new AbortController();

    return { call: http.put('userGrouping', {id, name}, { signal: controller.signal }), controller };
}

const updateGroupingUsers = (request: UpdateGroupingUsersDto) => {
    const controller = new AbortController();

    return { call: http.post<UpdateGroupingUsersDto>('userGrouping/setusers', request, { signal: controller.signal }), controller };
}

const deleteGrouping = (groupingId: string) => {
    const controller = new AbortController();

    return { call: http.delete(`userGrouping/${groupingId}`, { signal: controller.signal }), controller };
}

const getFile = (fileId: string) => {
    const controller = new AbortController();

    return { call: http.get<UserGroupingFileModelService>(`userGrouping/getfile/${fileId}`, { signal: controller.signal }), controller };
} 

const getFiles = (request: GetGroupingFilesDto) => {
    const controller = new AbortController();

    return { call: http.post<GetGroupingFilesDto>('userGrouping/getfiles', request, { signal: controller.signal }), controller };
}

const deleteFile = (fileId: string) => {
    const controller = new AbortController();

    return { call: http.delete(`userGrouping/file/${fileId}`, { signal: controller.signal }), controller };
}

const createGroupingBlob = (request: CreateGroupingFileDto) => {
    const controller = new AbortController();

    return { call: http.post("userGrouping/file", request, { signal: controller.signal }), controller };
}

const createGroupingLargeBlob = (request: CreateLargeGroupingFileDto) => {
    const controller = new AbortController();

    return { call: http.post("userGrouping/largeFile", request, { signal: controller.signal }), controller };
}

const getTypes = (request: GetTypesDto) => {
    const controller = new AbortController();

    return { call: http.get<GeneralCollection<UserGroupingTypeModelService>>(EncodeGetURI('userGrouping/type', request), { signal: controller.signal }), controller };
}

const getCategories = (request: GetCategoriesDto) => {
    const controller = new AbortController();

    return { call: http.get<GeneralCollection<UserGroupingCategoryModelService>>(EncodeGetURI('userGrouping/category', request), { signal: controller.signal }), controller };
}

const getTags = (request: GetTagsDto) => {
    const controller = new AbortController();

    return { call: http.get<GeneralCollection<UserGroupingTagModelService>>(EncodeGetURI('userGrouping/tag', request), { signal: controller.signal }), controller };
}

const updateGroupingFile = (fileId: string, property: string, value: string) => {
    const controller = new AbortController();

    return { call: http.patch(`userGrouping/updateFile/${fileId}`, [{
        op: "add",
        path: `/${property}`,
        value: value
    }], { signal: controller.signal, headers: {'Content-Type': 'application/json-patch+json'} }), controller };
}

const updateGroupingFileTags = (fileId: string, value: string[]) => {
    const controller = new AbortController();

    return { call: http.patch(`userGrouping/updateFile/${fileId}`, [{
        op: "add",
        path: "/tags",
        value: value
    }], { signal: controller.signal, headers: {'Content-Type': 'application/json-patch+json'} }), controller };
}

const updateGroupingFileTypeCategory = (fileId: string, type: string, category: string) => {
    const controller = new AbortController();

    return { call: http.patch(`userGrouping/updateFile/${fileId}`, [{
        op: "add",
        path: "/kindCategory",
        value: {
            kind: type,
            category: category
        }
    }], { signal: controller.signal, headers: {'Content-Type': 'application/json-patch+json'} }), controller };
}

export const groupingServices = {
    getGroupings,
    getGrouping,
    createGrouping,
    updateGroupingName,
    updateGroupingUsers,
    deleteGrouping,
    getFile,
    getFiles,
    deleteFile,
    createGroupingBlob,
    createGroupingLargeBlob,
    getTypes,
    getCategories,
    getTags,
    updateGroupingFile,
    updateGroupingFileTags,
    updateGroupingFileTypeCategory
}