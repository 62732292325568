import { Box } from '@mui/material';
import { useEffect } from 'react';
import { Margin, usePDF } from 'react-to-pdf';
import { useOrder } from 'src/hooks/use-order.hook';
import OrderArticles from '../order-articles';
import OrderHeader from '../order-header';
import OrderTotals from '../order-totals';
import './pdf-order.scss';

const PdfOrder = ({
  orderId,
  close,
  date,
  title,
}: {
  orderId: string;
  close: () => void;
  date: Date;
  title: string;
}) => {
  const { toPDF, targetRef } = usePDF({ filename: title + '.pdf' });
  const { data, isLoading } = useOrder(orderId);

  useEffect(() => {
    setTimeout(() => {
      toPDF({
        page: {
          margin: Margin.MEDIUM,
        },
      });
      close();
    }, 3000);
  }, []);

  if (!data) {
    return null;
  }

  return (
    <div ref={targetRef} style={{ position: 'absolute', zIndex: '-1', top: 0, left: '-99999999' }}>
      <Box className="order-main-pdf">
        <OrderHeader
          editable={false}
          orderData={data}
          currentState={data.statusId}
          setCurrentState={() => {}}
          orderEdited={false}
        />
        <OrderTotals orderData={data} orderEdited={false} />
        <OrderArticles
          forzeDesktop={true}
          orderData={data}
          updateOrderData={() => {}}
          handleRemoveArticle={() => {}}
          editable={false}
        />
      </Box>
    </div>
  );
};

export default PdfOrder;
