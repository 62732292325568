import CommentIcon from '@mui/icons-material/Comment';
import { Box } from '@mui/material';
import { FC } from 'react';
import { FormattedDate } from 'react-intl';
import { SaleOrderIncidenceModel } from 'src/models/orders';
import { IncidenceStatusText } from '../incidence-status-text';
import './hmy-card-incidences.scss';
import { useNavigate } from 'react-router';

type HmyCardIncidencesProps = {
  incidence: SaleOrderIncidenceModel;
};

const HmyCardIncidences: FC<HmyCardIncidencesProps> = ({ incidence }) => {
  const navigate = useNavigate();

  const handleClickIncidence = () => {
    navigate(`/incidence/${incidence.incidenceId}`);
  };

  return (
    <Box className="card-incidences-container">
      <Box className="card-incidences-item" onClick={handleClickIncidence}>
        <Box style={{ justifyContent: 'start', alignItems: 'center', display: 'flex' }}>
          <Box style={{ width: '40px', justifyContent: 'start', alignItems: 'center', display: 'flex' }}>
            {incidence.unreadComments > 0 && <CommentIcon style={{ width: '20px', color: 'green' }} />}
          </Box>
          {incidence.number}
        </Box>
        <Box>
          <IncidenceStatusText statusNumber={incidence.statusNumber} />
        </Box>

        <FormattedDate year="numeric" month="2-digit" day="2-digit" value={incidence.date} />
      </Box>
    </Box>
  );
};

export default HmyCardIncidences;
