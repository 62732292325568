import './hmy-input-file.scss';
import { ChangeEvent, FC } from 'react'
import { Box, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';

type HmyInputFileProps = {
    title: string; //Esto tiene que ser el id de la traducción
    width: number;
    setFile: (files: File[]) => void;
  };

export const HMY_INPUT_FILE_ID = "hmy-input-file";
const HmyInputFile: FC<HmyInputFileProps> = ({title, width, setFile}) => {

    const mapFileListToArray = (files: FileList): File[] => {
        let array: File[] = []
    
        for (let i = 0; i < files.length; i++) {
            const newFile = files.item(i);
            if(newFile !== null){
                array.push(newFile);
            }
            
        }
        return array;
    }

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        const files = event.target.files;
        if(files !== null){
            const newFiles = mapFileListToArray(files);
            setFile(newFiles);
        }
    }

    const handleClick = () => {
        document.getElementById("hmy-input-file")?.click();
    }

  return (
    <Box 
        className="hmy-input-file" 
        style={{ width: width > 0 ? `${width}px` : "auto"}}
        onClick={handleClick}
    >
        <Typography  className="custom-file-upload">
          <input id={HMY_INPUT_FILE_ID} type="file" multiple={false} onChange={(e) => handleChange(e)} />
          <FormattedMessage id={title}/>
        </Typography>
    </Box>
  )
}

export { HmyInputFile }