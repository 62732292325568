import './hmy-card-project.scss';
import { FC } from 'react';
import { BasicProject, CurrentUser } from 'src/models';
import { Box } from '@mui/material';
import { adjustString, IsAdmin } from 'src/utilities';
import { StringMultiple } from 'src/components';
import DeleteIcon from '@mui/icons-material/Delete';
import SettingsBackupRestoreIcon from '@mui/icons-material/SettingsBackupRestore';
import { FormattedDate } from 'react-intl';

type HmyCardProjectProps = {
  project: BasicProject;
  currentUser: CurrentUser;
  handleClickProject: (projectId: string) => void;
  setSelectedProject: (project: BasicProject) => void;
};

const HmyCardProject: FC<HmyCardProjectProps> = ({project, currentUser, handleClickProject, setSelectedProject}) => {
  return (
    <Box  
      className={project.isClosed ? "card-project-container card-project-isClosed" : (project.isDeleted ? "card-project-container isDeleted" : "card-project-container")}
    >
      <Box
        className="card-project-number"
      >
        <Box
          onClick={() => handleClickProject(project.projectId)}
        >
          {IsAdmin(currentUser) ? `${project.sourceId}-${project.projectNumber}` : project.projectNumber}
        </Box>
        <Box>
          <FormattedDate year="numeric" month="2-digit" day="2-digit" value={project.date} />
        </Box>
      </Box>

      <Box
        onClick={() => handleClickProject(project.projectId)}
      >
        {adjustString(project.projectName ?? project.description, 25)}
      </Box>

      <Box className=".card-project-users" >
        {currentUser.hmy && project.users.filter(x => x.profileName.toLowerCase() === "customer").length > 0
          ?
            <StringMultiple 
              elements={(project.users.filter(x => x.profileName.toLowerCase() === "customer")).map(x => x.fullName)}
              cutString={0}
            />
          :
            (!currentUser.hmy
            ?
              project.users.filter(x => x.profileName.toLowerCase() === "Commercial")[0]?.fullName
            :
              null
            )
        }
        <Box>
          {IsAdmin(currentUser)
          ?
            null
          :
            (project.isDeleted
              ?
              <SettingsBackupRestoreIcon
                className="delete-icon"
                fontSize="small"
                onClick={() => setSelectedProject(project)}
              />
              :
              <DeleteIcon 
                className="delete-icon"
                fontSize="small"
                onClick={() => setSelectedProject(project)}
              />
            )
          }
        </Box>
      </Box>
    </Box>
  )
}

export default HmyCardProject;