import './reports.scss';
import {FC, useEffect, useState} from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Box, IconButton, Tooltip, Typography, useMediaQuery } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { HmyBtn, HmyIconLaunch, HmyModalBasic, LoadComponent, ModalComponent } from 'src/components';
import Iframe from 'react-iframe';
import AddIcon from '@mui/icons-material/Add';
import CAN from 'src/permissions';
import { ADD_REPORTS } from 'src/permissions/permissions';
import ReportNew from './report-new';
import { useDispatch } from 'react-redux';
import { setMessage } from 'src/redux/states';
import { BasicReport, ErrorMessage } from 'src/models';
import ReportUserList from './user-list';
import { useAsync, useFetchAndLoad } from 'src/hooks';
import { BasicReportModelService, reportServices } from 'src/services';
import { basicReportAdapater } from 'src/adapters';
import { CreateReportDto } from 'src/dtos';
import DeleteIcon from '@mui/icons-material/Delete';
import ErrorIcon from '@mui/icons-material/Error';

const Reports: FC = () => {

    const dispatch = useDispatch();
    const matches = useMediaQuery('(max-width: 768px)');
    const { loading, callEndpoint } = useFetchAndLoad();
    const [addReport, setAddReport]= useState<boolean>(false);
    const [openModal, setOpenModal]= useState<boolean>(false);
    const [openDeleteModal, setOpenDeleteModal]= useState<boolean>(false);
    const [selectedReport, setSelectedReport]= useState<string | null>(null);
    const [openUsersForm, setOpenUsersForm] = useState<boolean>(false);
    const [reports, setReports] = useState<BasicReport[]>([]);
    const [selectedDeleteReport, setSelectedDeleteReport] = useState<BasicReport>();

    const getReports = async () => await callEndpoint(reportServices.getReports());
    const addNewReport = async (value: CreateReportDto) => await callEndpoint(reportServices.createReport(value));
    const deleteReport = async (reportId: string) => await callEndpoint(reportServices.deleteReport(reportId));

    const adaptReports = (data: BasicReportModelService[]) => {
      let newReports = data.map(x => basicReportAdapater(x));
      
      setReports(newReports);
    };

    const handleClose = () => {
        setOpenModal(false);
        setSelectedReport(null);
      }
    
      const handleOpen = (source: string) => {
        setSelectedReport(source);
        setOpenModal(true);
      }
    
    const handleCancelAddReport = () => {
        setAddReport(false);
    }

    const reloadReports = () => {
      getReports().then(response => {
        if(response.status === 200){
          adaptReports(response.data);
        }
      })
    }

    const handleAddReport = async (name: string, link: string) => {
        let definitiveLink = link;
        if(link.toLowerCase().includes("google.com") && !link.toLowerCase().includes("/embed/")){
            const newChain = link.split("google.com");
            definitiveLink = `${newChain[0]}google.com/embed${newChain[1]}`;
        }

        try {
          await addNewReport({title: name, link: definitiveLink});
          handleCancelAddReport();
          reloadReports();
        } catch (error: any) {
          dispatch(setMessage(ErrorMessage(error.data.errors.id, true)));
        }
      }

      const handleDeleteReport = async () => {
        if(selectedDeleteReport){
          try {
            await deleteReport(selectedDeleteReport.reportId);
            handleDismissDeleteModal();
            reloadReports();
          } catch (error: any) {
            dispatch(setMessage(ErrorMessage(error.data.errors.id, true)));
          }
        }
      }

      const handleDismissDeleteModal = () => {
        setOpenDeleteModal(false);
        setSelectedDeleteReport(undefined);
      }

    useEffect(() => {
      if(selectedDeleteReport !== undefined){
        setOpenDeleteModal(true);
      }
    },[selectedDeleteReport]);

    useAsync(getReports, adaptReports, () => { }, () => { }, []);

  return (
    <Box
      className="reports-container"
    >
        <Typography
            className="reports-title"
        >
            <FormattedMessage id="reports" />     
        </Typography>
        {CAN(ADD_REPORTS) && (
          addReport 
            ? 
              <Box className="report-new-container">
                <ReportNew 
                    handleAddReport={handleAddReport}
                    handleCancelAdd={handleCancelAddReport}
                />
              </Box>
            :
              <Box
                  className="reports-add"
              >   
                  <HmyBtn 
                      title="addReport"
                      fullwith={false}
                      width={matches ? 230 : 270}
                      color="secondary"
                      handleClick={() => setAddReport(!addReport)}
                  />
              </Box>
        )}
        {loading
          ?
            <LoadComponent />
        :
          <Box>
            {reports.map(report => (
                <Box key={report.reportId} className="report-element">
                    <Box className="report-accordion-container">
                        <Accordion className="report-accordion">
                            <AccordionSummary
                                expandIcon={<AddIcon fontSize="small" color="primary" />}
                            >
                                <span>{report.title}</span>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Box sx={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                                  <ReportUserList 
                                    reportId={report.reportId}
                                    title="customerAccounts"
                                    users={report.users}
                                    canEdit={CAN(ADD_REPORTS)}
                                    openForm={openUsersForm} 
                                    setOpenForm={setOpenUsersForm}
                                    reloadReports={reloadReports}
                                  />
                                </Box>
                            </AccordionDetails>
                        </Accordion>
                    </Box>

                    <Box className="report-actionButtons-container">
                      {CAN(ADD_REPORTS) 
                        ?
                          <Tooltip
                            title="Eliminar informe"
                          >
                            <Box
                                className="report-icon-delete"
                                onClick={() => setSelectedDeleteReport(report)}
                            >
                                <DeleteIcon />
                            </Box>
                          </Tooltip>          
                        :
                          null
                      }   
                      <Tooltip
                        title="Abrir informe"
                      >
                        <IconButton
                            onClick={() => handleOpen(report.link)}
                        >
                          <HmyIconLaunch 
                            fontSize="small"
                          />
                        </IconButton>
                      </Tooltip>  
                    </Box>
                </Box>
            ))}
          </Box>
        }
      <HmyModalBasic
        isOpen={openModal}
        handleClose={handleClose}    
      >
        <Box 
          className="report-iframe-container"
        >
          {selectedReport !== null
          ?
          <Iframe 
            url={selectedReport}
            id="drawing-Iframe"
            position="relative"
            scrolling="no"
            allowFullScreen={true}
            className="drawing-iframe"
          />
        :
        null
        }
        </Box>
      </HmyModalBasic>
      <ModalComponent
        isOpen={openDeleteModal}
        disMissText="remove"
        acceptText="btn.cancel"
        colorAcceptButton="red"
        handleDismiss={handleDismissDeleteModal}
        handleAccept={handleDeleteReport}
      >
        <Box>
          <ErrorIcon className="error-icon" />
        </Box>
        <Box
          className="report-modal-text"
        >
          <FormattedMessage id="deleteReport" values={{ value: <b>{selectedDeleteReport?.title}</b>}} />
        </Box>
      </ModalComponent>
    </Box>
    
  )
}

export default Reports