import { BasicOrder } from 'src/models/orders';
import { BasicShoppingOrder } from 'src/services/catalogue';
import { formatDate } from 'src/utilities';

export const calculeOrderDelivery = (orderData: BasicOrder | BasicShoppingOrder): string => {
  const order = orderData as BasicOrder;
  const maxDeliveryDays =
    order.lines?.reduce((maxDate: number, line) => (line.deliveryDays > maxDate ? line.deliveryDays : maxDate), 0) || 0;

  const currentDate = order.date ? new Date(order.date) : new Date();
  const deliveryDate = new Date(currentDate.setDate(currentDate.getDate() + maxDeliveryDays));

  return formatDate(deliveryDate);
};
